import React from 'react';
import { API_URL } from '../../apiConfig';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';
import Dropdown from './dropdowm';

const CreateServiceCharge = ({ onSelect }) => {
  // const [formData, setFormData] = useState({});
  const [token, setToken] = useState(null);
  const [charge, setCharge] = useState('');
  const [is_active, setIsActive] = useState(true);
  const [is_percentage, setHasPercentage] = useState(true);
  const [message, setMessage] = useState('');

  // const [selectedOption, setSelectedOption] = useState('');
  // const [formData, setFormData] = useState({
  //   charge: '',
  //   active: '',
  //   percentage: '',
  // });

  // const handleChange = (event) => {
  //   setFormData({ ...formData, [event.target.name]: event.target.value });
  // };

  // const handleDropdownSelect = (option) => {
  //   setFormData({ ...formData, percentage: option.value });
  // };

  // const handleDropdownSelect = (option) => {
  //   setSelectedOption(option);
  // };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  // const handleChange = (event) => {
  //     const formData = {
  //       [event.target.name]: event.target.value,
  //       is_active: selectedOption // Include the selected dropdown option value in the formData object

  //     };
  //     setFormData(prev => ({...prev, ...formData}))
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      charge,
      is_active,
      is_percentage,
    };
    await axios
      .post(`${API_URL}service-charges`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMessage(res.data.message);
        console.log(res.data);
        // return alert("added successfully");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function handleChargeChange(event) {
    setCharge(event.target.value);
  }

  function handleActiveChange(event) {
    setIsActive(event.target.value === 'true');
  }

  function handlePercentageChange(event) {
    setHasPercentage(event.target.value === 'true');
  }

  return (
    <div className=''>
      <div className='row'>
        <div className='col-md-10 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>CREATE SERVICE CHARGE</h4>
              {message && <Alert variant='success'>{message}</Alert>}
              <form className='forms-sample' onSubmit={handleSubmit}>
                <div className='form-group row'>
                  <label
                    for='exampleInputUsername2'
                    className='col-sm-3 col-form-label text-muted'
                  >
                    Charge
                  </label>
                  <div className='col-sm-5'>
                    <input
                      type='number'
                      id='charge'
                      name='charge'
                      value={charge}
                      onChange={handleChargeChange}
                      className='form-control'
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <label
                    htmlFor='exampleInputUsername2'
                    className='col-sm-3 col-form-label text-muted'
                  >
                    Active
                  </label>
                  <div className='col-sm-5'>
                    <select
                      id='active'
                      name='active'
                      value={is_active}
                      onChange={handleActiveChange}
                      className='form-control'
                    >
                      <option value='true'>true</option>
                      <option value='false'>false</option>
                    </select>
                  </div>
                </div>
                <div className='form-group row'>
                  <label
                    for='exampleInputUsername2'
                    className='col-sm-3 col-form-label text-muted'
                  >
                    Percentage
                  </label>
                  <div className='col-sm-5'>
                    <select
                      id='percentage'
                      name='percentage'
                      value={is_percentage}
                      onChange={handlePercentageChange}
                      className='form-control'
                    >
                      <option value='true'>true</option>
                      <option value='false'>false</option>
                    </select>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary mr-2'>
                  Submit
                </button>
                <button className='btn btn-light'>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateServiceCharge;
