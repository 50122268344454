import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { format } from 'date-fns';
import axios from 'axios';

export default function ViewWaitlist() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [waitlists, setWaitlists] = useState([]);
  const [token, setToken] = useState(null);
  // const [message, setMessage] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  // GET with fetch API
  //   useEffect(() => {
  //     const fetchWaitlists = async () => {
  //        const response = await fetch(
  //           'https://offmyyard-api.herokuapp.com/api/v1/waitlists', {
  //               headers: {
  //                 Authorization: `Bearer ${token}`,
  //               },
  //             })
  //        const data = await response.json();
  //        setWaitlists(data.data);
  //     };
  //     fetchWaitlists();
  //  }, []);

  useEffect(() => {
    const fetchWaitlists = async () => {
      try {
        const response = await axios.get(`${API_URL}/waitlists`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWaitlists(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (token) {
      fetchWaitlists();
    }
  }, [token]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>ALL WAITLIST</h4>
              {/* <p className="card-description">
                Add className <code>.table-striped</code>
              </p> */}
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Service</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {waitlists
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((waitlist, index) => (
                        <tr key={index}>
                          <td>{waitlist.name}</td>
                          <td>{waitlist.email}</td>
                          <td>{waitlist.service}</td>
                          <td>
                            {format(
                              new Date(waitlist.created_at),
                              'yyyy/MM/dd kk:mm:ss'
                            )}
                          </td>
                          <td>
                            <Link to={`/waitlistdetails/${waitlist.slug}`}>
                              <button
                                type='button'
                                className='btn btn-inverse-warning btn-fw btn-sm ml-3'
                                style={orangeButtonStyle}
                              >
                                Info
                              </button>
                            </Link>
                            <button className='btn btn-outline-danger btn-sm ml-3'>
                              <i className='remove ti-trash'></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component='div'
                  rowsPerPage={rowsPerPage}
                  count={waitlists.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
