import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import {Container, Row, Col} from 'react-bootstrap';


export default function ModelDetails() {
  const [rowData, setRowData] = useState(null);
  const {slug} = useParams();
  
  
  useEffect(() => {   
    // Make the API call to fetch the whole row data using the stored field value
    if (slug) {
      console.log(slug);
      axios.get(`${API_URL}/models/${slug}`)
        .then(response => {
          setRowData(response.data.data); // Store the row data in state
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  const formatDateTime = (dateTime) => {
    const dateOptions = { day: "numeric", month: "long", year: "numeric" };
    const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };

    const formattedDate = new Date(dateTime).toLocaleDateString("en-US", dateOptions);
    const formattedTime = new Date(dateTime).toLocaleTimeString("en-US", timeOptions);

    return `${formattedDate} | ${formattedTime}`;
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746'
    
  };
  
    return (              
      <div className="">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">MODEL DETAILS</h4><hr></hr>
                {rowData && (
                  <Container >
                    <Row style={{ height: "50px" }}>
                        <Col>Name</Col>
                        <Col>{rowData.name}</Col>
                      </Row>   
                      <Row style={{ height: "50px" }}>
                        <Col>Description</Col>
                        <Col>{rowData.description}</Col>
                      </Row>
                      <Row style={{ height: "50px" }}>
                        <Col>Date Created</Col>
                        <Col>{formatDateTime(rowData.created_at)}</Col>
                      </Row>  
                    
                  </Container>                      
                                                                                     
                )}                  
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
