import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import { API_URL } from '../apiConfig';

const LoginUser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${API_URL}/login`, {
        email,
        password,
      });
      //  return console.log(response);
      localStorage.setItem('token', response.data.token);
      navigate('/home');
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className='container-scroller'>
      <div className='container-fluid page-body-wrapper full-page-wrapper'>
        <div className='content-wrapper d-flex align-items-center auth px-0'>
          <div className='row w-100 mx-0'>
            <div className='col-lg-4 mx-auto'>
              <div className='auth-form-light text-left py-5 px-4 px-sm-5'>
                <div className='brand-logo align-items-center'>
                  <img src='assets/images/Offmyyard_01.png' alt='logo' />
                </div>
                <h4>Login</h4>
                <h6 className='font-weight-light'> Access to our dashboard</h6>
                <form className='pt-3' onSubmit={handleFormSubmit}>
                  <div className='form-group'>
                    <input
                      type='email'
                      className='form-control form-control-lg'
                      id='exampleInputEmail1'
                      placeholder='Email'
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className='form-group'>
                    <input
                      className='form-control form-control-lg'
                      type='password'
                      id='exampleInputPassword1'
                      placeholder='Password'
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </div>
                  <div className='mt-3'>
                    <button
                      type='submit'
                      className='btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn'
                    >
                      LOGIN
                    </button>
                  </div>
                  <div className='text-center mt-4 font-weight-light'>
                    Don't have an account?{' '}
                    <a href='' className='text-primary'>
                      Create
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginUser;
