import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';

export default function ItemDetails() {
  const [rowData, setRowData] = useState(null);
  const { slug } = useParams();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    if (token) {
      fetchItem();
    }
  }, [token]);

  const fetchItem = () => {
    // Make the API call to fetch the whole row data using the stored field value
    if (slug) {
      axios
        .get(`${API_URL}/items/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRowData(response.data.data); // Store the row data in state
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>ITEM DETAILS</h4>
              <hr></hr>
              {rowData && (
                <Container>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Title</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.title}</Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Description</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.description}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Price</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.price.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Negotiable</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.is_negotiable === 1 ? 'Yes' : 'No'}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Virtual Inspection</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.virtual_inspection === 1 ? 'Yes' : 'No'}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>
                      Physical Inspection
                    </Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.physical_inspection === 1 ? 'Yes' : 'No'}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Year</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.year}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Trim</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.trim}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Doc Type</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.doc_type}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Fuel</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.fuel}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Transmission</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.transmission}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Run Drive</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.run_drive}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Vin</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.vin}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Cylinders</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.cylinders}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Car seating</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.car_seating}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Latitude</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.latitude}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Longitude</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.longitude}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Country</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.country}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>State</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.state}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>City</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.city}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Zip Code</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.zip_code}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Status</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.status}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>search_index</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.search_index}
                    </Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Created At</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {format(
                        new Date(rowData.created_at),
                        'yyyy/MM/dd kk:mm:ss'
                      )}
                    </Col>
                  </Row>
                </Container>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
