import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { format } from 'date-fns';

export default function ViewMeetings() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [meetings, setMeetings] = useState([]);  

  // GET with fetch API
  useEffect(() => {
    const fetchMeetings = async () => {
       const response = await fetch(
        `${API_URL}/meetings`
       );
       const data = await response.json();
       setMeetings(data.data);
    };
    fetchMeetings();
 }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746'
    
  };

  
    return (              
      <div className="container-scroller">

      <div className="container-fluid page-body-wrapper">
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
                    <ul className="nav">
                        <li className="nav-item">
                            <Link to='/home' className="nav-link" >
                                <i className="icon-grid menu-icon"></i>                        
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/viewcustomers' className="nav-link">
                                <i className="icon-head menu-icon"></i>                        
                                <span className="menu-title">Customers</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/viewitems' className="nav-link" >
                                <i className="icon-grid-2 menu-icon"></i>                        
                                <span className="menu-title">Items</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
                            
                            <i className="icon-layout menu-icon"></i>
                            <span className="menu-title">Categories</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="auth">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/createcategories'> Create Categories </Link></a></li>
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/viewcategories'>View Categories</Link></a></li>
                            </ul>
                            </div>
                        </li>                                       
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#form-elements" aria-expanded="false" aria-controls="form-elements">
                            <i className="icon-bar-graph menu-icon"></i>
                            <span className="menu-title">Make</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="form-elements">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/createmake'> Create Make </Link></a></li>
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/viewmake'>View Make</Link></a></li>
                            </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#charts" aria-expanded="false" aria-controls="charts">
                            <i className="icon-columns menu-icon"></i>
                            <span className="menu-title">Model</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="charts">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/createmodel'> Create Model </Link></a></li>
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/viewmodel'>View Model</Link></a></li>
                            </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#icons" aria-expanded="false" aria-controls="icons">
                            <i className="icon-paper menu-icon"></i>
                            <span className="menu-title">Meeting</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="icons">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/createcategories'> Create Meeting </Link></a></li>
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/viewmeetings'>View Meeting</Link></a></li>
                            </ul>
                            </div>
                        </li>
                        {/* 
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#tables" aria-expanded="false" aria-controls="tables">
                            <i className="icon-grid-2 menu-icon"></i>
                            <span className="menu-title">Items</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="tables">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/createitem'> Create Items </Link></a></li>
                                <li className="nav-item"> <a className="nav-link" href=""><Link to='/viewitems'>View Items</Link></a></li>
                            </ul>
                            </div>
                        </li>     
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#charts" aria-expanded="false" aria-controls="charts">
                            <i className="icon-bar-graph menu-icon"></i>
                            <span className="menu-title">Charts</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="charts">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/charts/chartjs.html">ChartJs</a></li>
                            </ul>
                            </div>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#icons" aria-expanded="false" aria-controls="icons">
                            <i className="icon-contract menu-icon"></i>
                            <span className="menu-title">Icons</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="icons">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/icons/mdi.html">Mdi icons</a></li>
                            </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#auth" aria-expanded="false" aria-controls="auth">
                            <i className="icon-head menu-icon"></i>
                            <span className="menu-title">User Pages</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="auth">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/login.html"> Login </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/register.html"> Register </a></li>
                            </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="collapse" href="#error" aria-expanded="false" aria-controls="error">
                            <i className="icon-ban menu-icon"></i>
                            <span className="menu-title">Error pages</span>
                            <i className="menu-arrow"></i>
                            </a>
                            <div className="collapse" id="error">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/error-404.html"> 404 </a></li>
                                <li className="nav-item"> <a className="nav-link" href="pages/samples/error-500.html"> 500 </a></li>
                            </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="pages/documentation/documentation.html">
                            <i className="icon-paper menu-icon"></i>
                            <span className="menu-title">Documentation</span>
                            </a>
                        </li> */}
                    </ul>
                </nav>
        
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-muted">ALL MEETINGS</h4>
              {/* <p className="card-description">
                Add className <code>.table-striped</code>
              </p> */}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Meeting Date</th>
                      <th>Duration</th>
                      <th>Note</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                        {meetings
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((meeting, index) => (                            
                            <tr key={index}>
                              <td>{meeting.title}</td>
                              <td>{meeting.meeting_date}</td>
                              <td>{meeting.meeting_duration}</td>
                              <td>{meeting.meeting_note}</td>
                              <td>{format(new Date(meeting.created_at), 'yyyy/MM/dd kk:mm:ss')}</td>
                              <td>
                                  <Link to={`/meetingdetails/${meeting.id}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm" style={orangeButtonStyle}>Details</button></Link>
                                  {/* <button type="button" className="btn btn-outline-info btn-orange btn-sm " style={orangeButtonStyle}>Info</button> */}
                                  <Link to={`/editmodel/${meeting.id}`}><button className="btn btn-outline-success btn-sm ml-3"><i className=" ti-pencil-alt"></i> </button></Link>
                                  <button className="btn btn-outline-danger btn-sm ml-3"><i className="remove ti-trash"></i></button>
                                  
                                </td>
                              {/* <td>
                                <button className="badge badge-success"><i className=" ti-pencil-alt"></i> Edit</button>
                                <button className="badge badge-danger ml-3"><i className="remove ti-trash"></i>Delete</button>
                              </td> */}
                            </tr> 
                            ))}                         
                  </tbody>
                </table>
                <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={meetings.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                  />
              </div>
            </div>
          </div>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
