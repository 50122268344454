import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { format } from 'date-fns';

export default function ViewMake() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [makes, setMakes] = useState([]);  

  // GET with fetch API
  useEffect(() => {
    const fetchMakes = async () => {
       const response = await fetch(
        `${API_URL}/makes`
       );
       const data = await response.json();
       setMakes(data.data);
    };
    fetchMakes();
 }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746'
    
  };
  
    return (              
      <div className="">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">ALL MAKE</h4>
                {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                          {makes
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((make, index) => (                            
                              <tr key={index}>
                                <td>{make.name}</td>
                                <td>{make.description}</td>
                                <td>{format(new Date(make.created_at), 'yyyy/MM/dd kk:mm:ss')}</td>
                                <td>
                                  <Link to={`/makedetails/${make.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm" style={orangeButtonStyle}>Info</button></Link>                                 
                                  <Link to={`/editmake/${make.slug}`}><button className="btn btn-outline-success btn-sm ml-3"><i className=" ti-pencil-alt"></i> </button></Link>
                                  <button className="btn btn-outline-danger btn-sm ml-3"><i className="remove ti-trash"></i></button>
                                </td>
                              </tr> 
                              ))}                         
                          </tbody>
                  </table>
                  <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={makes.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[10, 25, 50]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
