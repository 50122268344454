import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProfilePage from './pages/withdrawalRequests/deet';
import ViewUsers from './pages/users/viewUsers';
import ViewTypes from './pages/types/viewTypes';
import ViewItems from './pages/items/viewItems';
import ViewMake from './pages/make/viewMake';
import ViewModel from './pages/model/viewModel';
import ViewCustomers from './pages/customers/viewCustomers';
import ViewWallet from './pages/wallet/viewWallet';
import ViewUSCustomers from './pages/customers/viewUSCustomers';
import ViewTransactions from './pages/transactions/viewTransactions';
import ViewServiceCharge from './pages/serviceCharge/viewServiceCharge';
import ViewShippingOffer from './pages/shippingOffers/viewShippingOffer';
import ViewWithdrawalRequests from './pages/withdrawalRequests/viewWithdrawalRequests';
import ViewCarriers from './pages/carriers/viewcarriers';
import ViewWaitlist from './pages/waitlist/viewWaitlist';
import ViewContactMessage from './pages/contactUs/viewContactMessage';
import CreateTypes from './pages/types/createTypes';
import CreateModel from './pages/model/createModel';
import CreateMake from './pages/make/createMake';
import CreateItem from './pages/items/createItem';
import CreateServiceCharge from './pages/serviceCharge/createServiceCharge';
import ItemDetails from './pages/items/ItemDetails';
import ModelDetails from './pages/model/modelDetails';
import ShippingOfferDetails from './pages/shippingOffers/shippingOfferDetails';
import MakeDetails from './pages/make/makeDetails';
import TransactionsDetails from './pages/transactions/transactionsDetails';
import TypeDetails from './pages/types/typeDetails';
import CustomerDetails from './pages/customers/customerDetails';
import ServiceChargeDetails from './pages/serviceCharge/serviceChargeDetail';
import WithdrawalRequestsDetails from './pages/withdrawalRequests/withdrawalRequestsDetails';
import CarrierDetails from './pages/carriers/carrierDetails';
import WaitlistDetails from './pages/waitlist/waitlistDetails';
import EditModel from './pages/model/editModel';
import EditTypes from './pages/types/editTypes';
import EditMake from './pages/make/editMake';
import EditServiceCharge from './pages/serviceCharge/editServiceCharge';
import ViewMeetings from './pages/meetings/viewMeetings';
import ProtectedRoute from './util/ProtectedRoute';
import Home from './pages/home'
import LoginUser from './auth/loginUser';
// import './auth/axiosInterceptor';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <Routes>
        <Route path='/' element={<LoginUser />} />
        <Route path="/" element={<App />}>
            <Route path='/home' element={ <ProtectedRoute> <Home /> </ProtectedRoute>} />
            <Route path='/profilepage/:slug' element={ <ProtectedRoute> <ProfilePage /> </ProtectedRoute>} />
            
            <Route path='/viewusers' element={ <ProtectedRoute> <ViewUsers /> </ProtectedRoute>} />

            <Route path='/viewcustomers' element={ <ProtectedRoute> <ViewCustomers /> </ProtectedRoute>} />
            <Route path='/viewuscustomers' element={ <ProtectedRoute> <ViewUSCustomers /> </ProtectedRoute>} />
            <Route path='/customersdetails/:slug' element={ <ProtectedRoute> <CustomerDetails /> </ProtectedRoute>} />

            <Route path='/viewtransactions' element={ <ProtectedRoute> <ViewTransactions /> </ProtectedRoute>} />
            <Route path='/transactionsdetails/:slug' element={ <ProtectedRoute> <TransactionsDetails /> </ProtectedRoute>} />

            <Route path='/viewtypes' element={ <ProtectedRoute> <ViewTypes /> </ProtectedRoute>} />
            <Route path='/createtypes' element={ <ProtectedRoute> <CreateTypes /> </ProtectedRoute>} />
            <Route path='/edittypes/:id' element={ <ProtectedRoute> <EditTypes /> </ProtectedRoute>} />
            <Route path='/typedetails/:slug' element={ <ProtectedRoute> <TypeDetails /> </ProtectedRoute>} />

            <Route path='/viewitems' element={ <ProtectedRoute> <ViewItems /> </ProtectedRoute>} />
            <Route path='/createitem' element={ <ProtectedRoute> <CreateItem /> </ProtectedRoute>} />
            <Route path='/itemdetails/:slug' element={ <ProtectedRoute> <ItemDetails /> </ProtectedRoute>} />
            
            <Route path='/editmake/:slug' element={ <ProtectedRoute> <EditMake /> </ProtectedRoute>} />
            <Route path='/viewmake' element={ <ProtectedRoute> <ViewMake /> </ProtectedRoute>} />
            <Route path='/createmake' element={ <ProtectedRoute> <CreateMake /> </ProtectedRoute>} />
            <Route path='/makedetails/:slug' element={ <ProtectedRoute> <MakeDetails /> </ProtectedRoute>} />
            
            <Route path='/viewmeetings' element={ <ProtectedRoute> <ViewMeetings /> </ProtectedRoute>} />
            
            <Route path='/editmodel/:slug' element={ <ProtectedRoute> <EditModel /> </ProtectedRoute>} />
            <Route path='/viewmodel' element={ <ProtectedRoute> <ViewModel /> </ProtectedRoute>} />
            <Route path='/createmodel' element={ <ProtectedRoute> <CreateModel /> </ProtectedRoute>} />
            <Route path='/modeldetails/:slug' element={ <ProtectedRoute> <ModelDetails /> </ProtectedRoute>} />

            <Route path='/viewshippingoffer' element={ <ProtectedRoute> <ViewShippingOffer /> </ProtectedRoute>} />
            <Route path='/shippingOfferdetails/:slug' element={ <ProtectedRoute> <ShippingOfferDetails /> </ProtectedRoute>} />

            <Route path='/viewcarriers' element={ <ProtectedRoute> <ViewCarriers /> </ProtectedRoute>} />
            <Route path='/carrierdetails/:slug' element={ <ProtectedRoute> <CarrierDetails /> </ProtectedRoute>} />

            <Route path='/viewwithdrawalrequests' element={ <ProtectedRoute> <ViewWithdrawalRequests /> </ProtectedRoute>} />
            <Route path='/withdrawalrequestsdetails/:slug' element={ <ProtectedRoute> <WithdrawalRequestsDetails /> </ProtectedRoute>} />

            <Route path='/viewservicecharge' element={ <ProtectedRoute> <ViewServiceCharge /> </ProtectedRoute>} />
            <Route path='/createservicecharge' element={ <ProtectedRoute> <CreateServiceCharge /> </ProtectedRoute>} />
            <Route path='/editserviceCharge/:slug' element={ <ProtectedRoute> <EditServiceCharge /> </ProtectedRoute>} />
            <Route path='/servicechargedetails/:slug' element={ <ProtectedRoute> <ServiceChargeDetails /> </ProtectedRoute>} />
            

            <Route path='/viewwaitlist' element={ <ProtectedRoute> <ViewWaitlist /> </ProtectedRoute>} />
            <Route path='/createservicecharge' element={ <ProtectedRoute> <CreateServiceCharge /> </ProtectedRoute>} />
            <Route path='/editserviceCharge/:slug' element={ <ProtectedRoute> <EditServiceCharge /> </ProtectedRoute>} />
            <Route path='/waitlistdetails/:slug' element={ <ProtectedRoute> <WaitlistDetails /> </ProtectedRoute>} />
           
            <Route path='/viewwallet' element={ <ProtectedRoute> <ViewWallet /> </ProtectedRoute>} />
            <Route path='/createservicecharge' element={ <ProtectedRoute> <CreateServiceCharge /> </ProtectedRoute>} />
            <Route path='/editserviceCharge/:slug' element={ <ProtectedRoute> <EditServiceCharge /> </ProtectedRoute>} />
            <Route path='/waitlistdetails/:slug' element={ <ProtectedRoute> <WaitlistDetails /> </ProtectedRoute>} />
           
            
            <Route path='/viewcontactmessage' element={ <ProtectedRoute> <ViewContactMessage /> </ProtectedRoute>} />
            

        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
