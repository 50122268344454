import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';

export default function TransactionsDetails() {
  const [rowData, setRowData] = useState(null);
  const { slug } = useParams();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    if (token) {
      fetchTransactions();
    }
  }, [token]);

  const fetchTransactions = () => {
    // Make the API call to fetch the whole row data using the stored field value
    if (slug) {
      axios
        .get(`${API_URL}/transactions/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRowData(response.data.data); // Store the row data in state
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>TRANSACTION DETAILS</h4>
              <hr></hr>
              {rowData && (
                <Container>
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>ID</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.id}</Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Amount</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Transaction Ref</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.transaction_ref}
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Transaction Status</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.transaction_status}
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Type</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.type}</Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Stripe Charge ID</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.stripe_charge_id}
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Description</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.description}
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Ledger</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.ledger}</Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Slug</Col>
                    <Col style={{ marginRight: '20rem' }}>{rowData.slug}</Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Make</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.relationships.offer.make}
                    </Col>
                  </Row>
                  <hr />{' '}
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Model</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.relationships.offer.model}
                    </Col>
                  </Row>
                  <hr />{' '}
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Year</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.relationships.offer.year}
                    </Col>
                  </Row>
                  <hr />{' '}
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>From</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.relationships.offer.from}
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>To</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.relationships.offer.to}
                    </Col>
                  </Row>
                  <hr />{' '}
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Distance</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {rowData.relationships.offer.distance}
                    </Col>
                  </Row>
                  <hr />
                  <Row style={{ height: '50px' }}>
                    <Col style={{ fontWeight: 'bold' }}>Created At</Col>
                    <Col style={{ marginRight: '20rem' }}>
                      {format(
                        new Date(rowData.created_at),
                        'yyyy/MM/dd kk:mm:ss'
                      )}
                    </Col>
                  </Row>
                </Container>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
