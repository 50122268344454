import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';

export default function ShippingOfferDetails() {
  const [rowData, setRowData] = useState(null);
  const { slug } = useParams();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    if (token) {
      fetchShippingOffer();
    }
  }, [token]);

  const fetchShippingOffer = () => {
    // Make the API call to fetch the whole row data using the stored field value
    if (slug) {
      axios
        .get(`${API_URL}/shipping-offers/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRowData(response.data.data); // Store the row data in state
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const orangeButtonStyle = {
    color: 'white',
    borderColor: '#F99746',
  };

  const handleClickScroll = () => {
    const element = document.getElementById('section-1');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickScroll2 = () => {
    const element = document.getElementById('section-offer');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section style={{ backgroundColor: '#eee' }}>
      <MDBContainer className='py-5'>
        <MDBRow id='section-offer'>
          <MDBCol>
            <MDBBreadcrumb className='bg-light rounded-3 p-3 mb-4'>
              <MDBBreadcrumbItem>
                <Link to='/home'>Home</Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem>
                <Link to='#'>shipping Offer </Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>
                shipping Offer Details
              </MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <div
              className='mb-3'
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                fontWeight: 'bold',
              }}
            >
              <button
                onClick={handleClickScroll}
                className='btn btn-primary'
                style={orangeButtonStyle}
              >
                View Bids
              </button>
            </div>
          </MDBCol>
        </MDBRow>
        {rowData && (
          <MDBRow>
            <MDBCol lg='12'>
              <MDBCard className='mb-4'>
                <MDBCardBody>
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Ship From</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.ship_from}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Ship To</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.ship_to}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Pickup Date</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.pickup_date}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Year</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.year}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Shipping Offer Amount</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.shipping_offer_amount.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Pickup Address</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.pickup_address}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Pickup Location Type</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.pickup_location_type}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Pickup Contact</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.pickup_contact}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Shipping Status</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.shipping_status}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Comment</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.comment}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Paid</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.is_paid === 1 ? 'Yes' : 'No'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Operable</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.is_operable === 1 ? 'Yes' : 'No'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Key Available</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.is_key_available === 1 ? 'Yes' : 'No'}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Drop Address</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.drop_address}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Drop Location Type</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.drop_location_type}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Down Payment</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.down_payment.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Status</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.status}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Customers Name</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.relationships.customer.name}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Customers Phone Number</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.relationships.customer.phone_number}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Customers Email</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.relationships.customer.email}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Customers Mobile</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.relationships.customer.phone_number}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Customers Username</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.relationships.customer.username}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Customers Country</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.relationships.customer.country}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Make</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.relationships.make}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm='3'>
                      <MDBCardText>Model</MDBCardText>
                    </MDBCol>
                    <MDBCol sm='9'>
                      <MDBCardText
                        style={{ fontWeight: 'bold' }}
                        className='text-muted'
                      >
                        {rowData.relationships.model}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>

              <MDBRow>
                {/* <MDBCol md="12">
                  <MDBCard className="mb-4 mb-md-0">
                    <MDBCardBody>
                      <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">assigment</span> Project Status</MDBCardText>
                      <MDBCardText className="mb-1" style={{ fontSize: '.77rem' }}>Web Design</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={80} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Website Markup</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={72} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>One Page</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={89} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Mobile Template</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Backend API</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                      </MDBProgress>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol> */}

                {/* <MDBCol md="6">
                  <MDBCard className="mb-4 mb-md-0">
                    <MDBCardBody>
                      <MDBCardText className="mb-4"><span className="text-primary font-italic me-1">assigment</span> Project Status</MDBCardText>
                      <MDBCardText className="mb-1" style={{ fontSize: '.77rem' }}>Web Design</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={80} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Website Markup</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={72} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>One Page</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={89} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Mobile Template</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={55} valuemin={0} valuemax={100} />
                      </MDBProgress>

                      <MDBCardText className="mt-4 mb-1" style={{ fontSize: '.77rem' }}>Backend API</MDBCardText>
                      <MDBProgress className="rounded">
                        <MDBProgressBar width={66} valuemin={0} valuemax={100} />
                      </MDBProgress>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol> */}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        )}

        <div id='section-1'>
          <div className='row'>
            <div className='col-lg-12 grid-margin stretch-card'>
              <div className='card'>
                <div className='card-body'>
                  <h4 className='card-title text-muted'>ALL BIDS</h4>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      fontWeight: 'bold',
                    }}
                  >
                    <button
                      onClick={handleClickScroll2}
                      className='btn btn-primary'
                      style={orangeButtonStyle}
                    >
                      View Offer
                    </button>
                  </div>
                  <hr></hr>
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th>Price</th>
                          <th>Driver Name</th>
                          <th>Email</th>
                          <th>Phone Number</th>
                          <th>Status</th>
                          <th>Down Payment</th>
                          <th>Carrier Name</th>
                          <th>Carrier Email</th>
                          <th>Carrier Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rowData && rowData.relationships && (
                          <>
                            {rowData.relationships.bids &&
                              Array.isArray(rowData.relationships.bids) &&
                              rowData.relationships.bids.map((bid) => (
                                <tr key={bid.id}>
                                  <td>
                                    {bid.price.toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    })}
                                  </td>
                                  <td>{bid.driver_name}</td>
                                  <td>{bid.email}</td>
                                  <td>{bid.phone_number}</td>
                                  <td>{bid.status}</td>
                                  <td>
                                    {bid.down_payment.toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    })}
                                  </td>
                                  <td>{bid.relationships.carrier.name}</td>
                                  <td>{bid.relationships.carrier.email}</td>
                                  <td>{bid.relationships.carrier.phone}</td>
                                </tr>
                              ))}
                            {rowData.relationships.bid && (
                              <tr key={rowData.relationships.bid.id}>
                                <td>
                                  {rowData.relationships.bid.price.toLocaleString(
                                    'en-US',
                                    { style: 'currency', currency: 'USD' }
                                  )}
                                </td>
                                <td>{rowData.relationships.bid.driver_name}</td>
                                <td>{rowData.relationships.bid.email}</td>
                                <td>
                                  {rowData.relationships.bid.phone_number}
                                </td>
                                <td>{rowData.relationships.bid.status}</td>
                                <td>
                                  {rowData.relationships.bid.down_payment.toLocaleString(
                                    'en-US',
                                    { style: 'currency', currency: 'USD' }
                                  )}
                                </td>
                                <td>
                                  {
                                    rowData.relationships.bid.relationships
                                      .carrier.name
                                  }
                                </td>
                                <td>
                                  {
                                    rowData.relationships.bid.relationships
                                      .carrier.email
                                  }
                                </td>
                                <td>
                                  {
                                    rowData.relationships.bid.relationships
                                      .carrier.phone
                                  }
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>

                      {/* <tbody>                           
                                        
                                          { rowData && Array.isArray(rowData.relationships.bids) && rowData.relationships.bids.map((bid) => (
      <tr key={bid.id}> console.log(rowData);
                                              <td>{bid.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                              <td>{bid.driver_name}</td>
                                              <td>{bid.email}</td>
                                              <td>{bid.phone_number}</td>
                                              <td>{bid.status}</td>
                                              <td>{bid.relationships.carrier.name}</td>
                                              <td>{bid.relationships.carrier.email}</td>
                                              <td>{bid.relationships.carrier.email}</td>
                                              <td>{bid.relationships.carrier.phone}</td>
                                          </tr>
                                          ))}                                          
                                      </tbody> */}

                      {/* {rowData && rowData.relationships.bids.map((bid) => (
                                          <tr key={bid.id}> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MDBContainer>
    </section>
  );
}

// <tbody>
//   {rowData && rowData.relationships && (
//       <>
//           {Array.isArray(rowData.relationships.bids) && rowData.relationships.bids.length > 0 && rowData.relationships.bids.map((bid) => (
//               <tr key={bid.id}>
//                   <td>{bid.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
//                   <td>{bid.status}</td>
//                   <td>{bid.relationships.carrier.name}</td>
//                   <td>{bid.relationships.carrier.email}</td>
//               </tr>
//           ))}
//           {rowData.relationships.bid && (
//               <tr key={rowData.relationships.bid.id}>
//                   <td>{rowData.relationships.bid.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
//                   <td>{rowData.relationships.bid.status}</td>
//                   <td>{rowData.relationships.bid.relationships.carrier.name}</td>
