import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { TablePagination, CircularProgress } from '@mui/material';

function UserStatusButton({ email, currentStatus }) {
  // console.log(currentStatus);
  const [userStatus, setUserStatus] = useState(currentStatus);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  const toggleStatus = () => {
    const newStatus = userStatus === 'ACTIVE' ? 'suspended' : 'active';

    axios
      .post(
        'https://offmyyardbackendstaging-api.azurewebsites.net/api/v1/suspend-and-activate-account',
        { email: email, status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setUserStatus((prevStatus) =>
          prevStatus === 'ACTIVE' ? 'suspended' : 'ACTIVE'
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <button class='btn btn-outline-success btn-sm ml-3' onClick={toggleStatus}>
      {userStatus === 'ACTIVE' ? 'Active' : 'Suspended'}
    </button>
  );
}

export default function ViewCustomers() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [customers, setCustomers] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  const fetchCustomers = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/customers/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
        },
      });
      setLoading(false);
      setCustomers(response.data.data);
      setPaginationData(response.data.meta);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchCustomers();
    }
  }, [token]);

  const handlePageChange = (event, newPage) => {
    setLoading(true);
    fetchCustomers(newPage + 1);
  };

  // useEffect(() => {
  //   setLoading(true);
  //   const fetchCustomers = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${API_URL}/customers?country=Nigeria`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       if (response.data) setLoading(false);
  //       setCustomers(response.data.data);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   if (token) {
  //     fetchCustomers();
  //   }
  // }, [token]);

  // useEffect(() => {console.log(customers)}, [customers])

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>All Customers</h4>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      {/* <th>Name</th> */}
                      <th>Username</th>
                      <th>Phone Number</th>
                      <th>Address</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan='7' style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {customers &&
                          customers.length > 0 &&
                          customers
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((customer, index) => (
                              <tr key={index}>
                                {/* Your table data here */}
                                <td>{customer.username}</td>
                                <td>{customer.phone_number}</td>
                                <td>{customer.address}</td>
                                <td>
                                  {format(
                                    new Date(customer.created_at),
                                    'yyyy/MM/dd kk:mm:ss'
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/customersdetails/${customer.slug}`}
                                  >
                                    <button
                                      type='button'
                                      className='btn btn-inverse-warning btn-fw btn-sm'
                                      style={orangeButtonStyle}
                                    >
                                      Info
                                    </button>
                                  </Link>
                                  <button className='btn btn-outline-danger btn-sm ml-3'>
                                    <i className='remove ti-trash'></i>
                                  </button>
                                  <UserStatusButton
                                    email={customer.email}
                                    currentStatus={customer.account_status}
                                  />
                                </td>
                              </tr>
                            ))}

                        {/* {customers && customers.length > 0 && customers
                              .filter((customer) => customer.relationships.country === "Nigeria") // Filter the data
                              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((customer, index) => (  
                              <tr key={index}>
                                // <td>{customer.name}</td> 
                                <td>{customer.username}</td>
                                <td>{customer.phone_number}</td>
                                <td>{customer.address}</td>
                                <td>{format(new Date(customer.created_at), 'yyyy/MM/dd kk:mm:ss')}</td>
                                <td>
                                    <Link to={`/customersdetails/${customer.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm" style={orangeButtonStyle}>Info</button></Link>                                 
                                    <button className="btn btn-outline-danger btn-sm ml-3"><i className="remove ti-trash"></i></button>                               
                                    // <button onClick={toggleStatus}>{userStatus === 'active' ? 'Suspend' : 'Activate'}</button> 
                                    <UserStatusButton email={customer.email} currentStatus={customer.account_status} />
                                </td>
                              </tr> 
                              ))}                     */}
                      </>
                    )}
                  </tbody>
                </table>
                {paginationData && (
                  <TablePagination
                    sx={{ px: 2 }}
                    page={paginationData ? paginationData.current_page - 1 : 0}
                    component='div'
                    rowsPerPage={paginationData ? paginationData.per_page : 10}
                    count={paginationData ? paginationData.total : 0}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[10, 25, 50]}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  />
                )}

                {/* {paginationData && (
                    <TablePagination
                    sx={{ px: 2 }}
                    page={paginationData ? paginationData.current_page - 1 : 0}
                    component="div"
                    rowsPerPage={paginationData ? paginationData.per_page : 10}
                    count={paginationData ? paginationData.total : 0}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[10, 25, 50]}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  />
                    )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
