import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../apiConfig';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { format } from 'date-fns';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';

export default function ViewWithdrawalRequests() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [token, setToken] = useState(null);

  // GET with fetch API
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    const fetchWithdrawalRequests = async () => {
      try {
        const response = await axios.get(`${API_URL}/withdrawals`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWithdrawalRequests(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (token) {
      fetchWithdrawalRequests();
    }
  }, [token]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>ALL WITHDRAWALS OFFERS</h4>
              {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>amount</th>
                      <th>remark</th>
                      <th>withdrawal_status</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {withdrawalRequests
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((withdrawalRequest, index) => (
                        <tr key={index}>
                          <td>
                            {withdrawalRequest.amount.toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </td>
                          <td>{withdrawalRequest.remark}</td>
                          <td>{withdrawalRequest.withdrawal_status}</td>
                          <td>
                            {format(
                              new Date(withdrawalRequest.created_at),
                              'yyyy/MM/dd kk:mm:ss'
                            )}
                          </td>
                          <td>
                            <Link
                              to={`/withdrawalrequestsdetails/${withdrawalRequest.slug}`}
                            >
                              <button
                                type='button'
                                className='btn btn-inverse-warning btn-fw btn-sm'
                                style={orangeButtonStyle}
                              >
                                Info
                              </button>
                            </Link>

                            {/* <Link to={`/withdrawalRequestdetails/${withdrawalRequest.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm" style={orangeButtonStyle}>Info</button></Link>                                 
                                  <Link to={`/editwithdrawalRequest/${withdrawalRequest.id}`}><button className="btn btn-outline-success btn-sm ml-3"><i className=" ti-pencil-alt"></i> </button></Link> */}
                            <button className='btn btn-outline-danger btn-sm ml-3'>
                              <i className='remove ti-trash'></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component='div'
                  rowsPerPage={rowsPerPage}
                  count={withdrawalRequests.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
