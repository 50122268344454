import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';

      const CreateItem = () => {
        const [formData, setFormData] = useState({});
        const [isAuthorized, setIsAuthorized] = useState(false);
        const [token, setToken] = useState(null);

        useEffect(() => {
            const storedToken = localStorage.getItem("token");
            setToken(storedToken);
          }, []);

        const handleChange = (event) => {
            const formData = {
              [event.target.name]: event.target.value,
              [event.target.description]: event.target.value,
            };
            setFormData({ ...formData });
          };

      const handleSubmit  = async (event) => {
        event.preventDefault();

        if (!token) {
            return;
          }
      
        if (!isAuthorized) {
          return alert("You are not authorized to perform this action");
        }
      
        const res = await axios
          .post(`${API_URL}/categories`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      };

  
    return (              
      <div class="container-scroller">
        <div class="row">
          <div class="col-md-10 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title text-muted">CREATE ITEM</h4>
                <form class="forms-sample" onSubmit={handleSubmit}>
                  <div class="form-group row">
                    <label for="exampleInputUsername2" class="col-sm-3 col-form-label text-muted">Name</label>
                    <div class="col-sm-9">
                      <input 
                          type="text" 
                          class="form-control" 
                          id="exampleInputUsername2" 
                          placeholder="Name" 
                          name="name"
                          onChange={handleChange}
                          value={formData.name}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="exampleInputUsername2" class="col-sm-3 col-form-label text-muted">Description</label>
                    <div class="col-sm-9">
                      <textarea 
                          type="text" 
                          class="form-control" 
                          id="exampleInputUsername2" 
                          placeholder="Description"
                          name="description"
                          onChange={handleChange}
                          value={formData.description} 
                      />
                    </div>
                  </div>                    
                  <button type="submit" class="btn btn-primary mr-2">Submit</button>
                  <button class="btn btn-light">Cancel</button>
                </form>
              </div>
            </div>
          </div>          
        </div>
      </div>
    )
  };

  export default CreateItem;
