// refreshToken.js
import axios from 'axios';

const RefreshToken = () => {
  const storedRefreshToken = localStorage.getItem('token');

  return axios
    .post('https://offmyyard-api.herokuapp.com/api/v1/refresh', {
      refresh_token: storedRefreshToken,
    })
    .then((response) => {
      const { token } = response.data;
      // Store the new access token
      localStorage.setItem('token', JSON.stringify(token));
      //   localStorage.setItem('token', JSON.stringify(response.data.token));
      return token;
    })
    .catch((error) => {
      console.error('Error refreshing access token:', error);
    });
};

export default RefreshToken;
