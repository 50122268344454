import React from 'react';
import { API_URL } from '../apiConfig';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { format } from 'date-fns';
import axios from 'axios';
// import {LineStyle, Timeline, TrendingUp, PermIdentity, Storefront, Report, WorkOutline, BarChart, DynamicFeed, MailOutline, AttachMoney, } from '@mui/icons-material';
import { useNavigate, Link } from 'react-router-dom';
export default function Home() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [carriers, setCarriers] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  //   useEffect(() => {
  //     if (localStorage.getItem('token'));
  //     navigate('/home');
  //   });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${API_URL}/dashboard-matrix`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setDashboardData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (token) {
      fetchDashboardData();
    }
  }, [token]);

  // GET with fetch API
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    const fetchCarriers = async () => {
      try {
        const response = await axios.get(`${API_URL}/carriers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCarriers(response.data.data.slice(0, 5));
      } catch (error) {
        console.error(error);
      }
    };
    if (token) {
      fetchCarriers();
    }
  }, [token]);

  useEffect(() => {
    setLoading(true);
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) setLoading(false);
        setUsers(response.data.data.slice(0, 5));
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };
    if (token) {
      fetchUsers();
    }
  }, [token]);

  const formatDateTime = (dateTime) => {
    const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

    const formattedDate = new Date(dateTime).toLocaleDateString(
      'en-US',
      dateOptions
    );
    const formattedTime = new Date(dateTime).toLocaleTimeString(
      'en-US',
      timeOptions
    );

    return `${formattedDate} | ${formattedTime}`;
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-md-12 grid-margin'>
          <div className='row  ms-5'>
            <div className='col-12 col-xl-8 mb-4 mb-xl-0 ms-5'>
              <h3 className='font-weight-bold ms-15'>Welcome Admin!</h3>
              {/* <h6 className="font-weight-normal mb-0">All systems are running smoothly! You have <span className="text-primary">3 unread alerts!</span></h6> */}
            </div>
            {/* <div className="col-12 col-xl-4">
                        <div className="justify-content-end d-flex">
                        <div className="dropdown flex-md-grow-1 flex-xl-grow-0">
                            <button className="btn btn-sm btn-light bg-white dropdown-toggle" type="button" id="dropdownMenuDate2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            <i className="mdi mdi-calendar"></i> Today (10 Jan 2021)
                            </button>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuDate2">
                            <a className="dropdown-item" href="#">January - March</a>
                            <a className="dropdown-item" href="#">March - June</a>
                            <a className="dropdown-item" href="#">June - August</a>
                            <a className="dropdown-item" href="#">August - November</a>
                            </div>
                        </div>
                        </div>
                        </div> */}
          </div>
        </div>
      </div>

      <div className='row'>
        {dashboardData && (
          <div className='col-md-12 grid-margin transparent'>
            <div className='row'>
              <div className='col-md-3 mb-4 stretch-card transparent'>
                <div className='card card-tale'>
                  <div className='card-body'>
                    <p className='mb-4'>US Users </p>
                    <p className='fs-30 mb-2'>{dashboardData.customer.us}</p>
                    {/* <p>10.00% (30 days)</p> */}
                  </div>
                </div>
              </div>
              <div className='col-md-3 mb-4 stretch-card transparent'>
                <div className='card card-dark-blue'>
                  <div className='card-body'>
                    <p className='mb-4'>Nigerian Users</p>
                    <p className='fs-30 mb-2'>
                      {dashboardData.customer.nigeria}
                    </p>
                    {/* <p>22.00% (30 days)</p> */}
                  </div>
                </div>
              </div>
              <div className='col-md-3 mb-4 stretch-card transparent'>
                <div className='card card-light-blue'>
                  <div className='card-body'>
                    <p className='mb-4'>Carrier</p>
                    <p className='fs-30 mb-2'>{dashboardData.carrier}</p>
                    {/* <p>2.00% (30 days)</p> */}
                  </div>
                </div>
              </div>
              <div className='col-md-3 mb-4 stretch-card transparent'>
                <div className='card card-light-danger'>
                  <div className='card-body'>
                    <p className='mb-4'>Offers</p>
                    <p className='fs-30 mb-2'>{dashboardData.offers}</p>
                    {/* <p>0.22% (30 days)</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* 
                    <div>
                        <div className="row">
                        <div className="col-md-6 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                            <p className="card-title">Order Details</p>
                            <p className="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>
                            <div className="d-flex flex-wrap mb-5">
                                <div className="mr-5 mt-3">
                                <p className="text-muted">Order value</p>
                                <h3 className="text-primary fs-30 font-weight-medium">12.3k</h3>
                                </div>
                                <div className="mr-5 mt-3">
                                <p className="text-muted">Orders</p>
                                <h3 className="text-primary fs-30 font-weight-medium">14k</h3>
                                </div>
                                <div className="mr-5 mt-3">
                                <p className="text-muted">Users</p>
                                <h3 className="text-primary fs-30 font-weight-medium">71.56%</h3>
                                </div>
                                <div className="mt-3">
                                <p className="text-muted">Downloads</p>
                                <h3 className="text-primary fs-30 font-weight-medium">34040</h3>
                                </div> 
                            </div>
                            <canvas id="order-chart"></canvas>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-6 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                            <div className="d-flex justify-content-between">
                            <p className="card-title">Sales Report</p>
                            <a href="#" className="text-info">View all</a>
                            </div>
                            <p className="font-weight-500">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>
                            <div id="sales-legend" className="chartjs-legend mt-4 mb-2"></div>
                            <canvas id="sales-chart"></canvas>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                            <div className="card position-relative">
                            <div className="card-body">
                            <div id="detailedReports" className="carousel slide detailed-report-carousel position-static pt-2" data-ride="carousel">
                                <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="row">
                                    <div className="col-md-12 col-xl-3 d-flex flex-column justify-content-start">
                                        <div className="ml-xl-4 mt-3">
                                        <p className="card-title">Detailed Reports</p>
                                        <h1 className="text-primary">$34040</h1>
                                        <h3 className="font-weight-500 mb-xl-4 text-primary">North America</h3>
                                        <p className="mb-2 mb-xl-0">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>
                                        </div>  
                                        </div>
                                    <div className="col-md-12 col-xl-9">
                                        <div className="row">
                                        <div className="col-md-6 border-right">
                                            <div className="table-responsive mb-3 mb-md-0 mt-3">
                                            <table className="table table-borderless report-table">
                                            <tbody>
                                                <tr>
                                                <td className="text-muted">Illinois</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: "70%" }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">713</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">Washington</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: "30%" }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">583</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">Mississippi</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: "95%" }} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">924</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">California</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-info" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">664</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">Maryland</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: "40%" }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">560</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">Alaska</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">793</h5></td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <canvas id="north-america-chart"></canvas>
                                            <div id="north-america-legend"></div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="carousel-item">
                                    <div className="row">
                                    <div className="col-md-12 col-xl-3 d-flex flex-column justify-content-start">
                                        <div className="ml-xl-4 mt-3">
                                        <p className="card-title">Detailed Reports</p>
                                        <h1 className="text-primary">$34040</h1>
                                        <h3 className="font-weight-500 mb-xl-4 text-primary">North America</h3>
                                        <p className="mb-2 mb-xl-0">The total number of sessions within the date range. It is the period time a user is actively engaged with your website, page or app, etc</p>
                                        </div>  
                                        </div>
                                    <div className="col-md-12 col-xl-9">
                                        <div className="row">
                                        <div className="col-md-6 border-right">
                                            <div className="table-responsive mb-3 mb-md-0 mt-3">
                                            <table className="table table-borderless report-table">
                                            <tbody>
                                                <tr>
                                                <td className="text-muted">Illinois</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: "70%" }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">713</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">Washington</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: "30%" }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">583</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">Mississippi</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: "95%" }} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">924</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">California</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-info" role="progressbar" style={{ width: "60%" }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">664</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">Maryland</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-primary" role="progressbar" style={{ width: "40%" }} aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">560</h5></td>
                                                </tr>
                                                <tr>
                                                <td className="text-muted">Alaska</td>
                                                <td className="w-100 px-0">
                                                    <div className="progress progress-md mx-4">
                                                    <div className="progress-bar bg-danger" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </td>
                                                <td><h5 className="font-weight-bold mb-0">793</h5></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-3">
                                            <canvas id="south-america-chart"></canvas>
                                            <div id="south-america-legend"></div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <a className="carousel-control-prev" href="#detailedReports" role="button" data-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="sr-only">Previous</span>
                                </a>
                                <a className="carousel-control-next" href="#detailedReports" role="button" data-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="sr-only">Next</span>
                                </a>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>    */}
      <div className='row'>
        <div className='col-md-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <p className='card-title mb-3'>carriers to be approved</p>
              <div className='table-responsive'>
                <table className='table table-striped table-borderless'>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Company Address</th>
                      <th>City</th>
                      <th>Phone Number</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {carriers
                      .filter((carrier) => carrier.status === 'PENDING')
                      .map((carrier) => (
                        <tr key={carrier.id}>
                          <td>{carrier.company_name}</td>
                          <td>{carrier.company_address}</td>
                          <td>{carrier.city}</td>
                          <td>{carrier.local_phone_number}</td>
                          <td>
                            {carrier.status === 'APPROVED' ? (
                              <button
                                type='button'
                                className='btn btn-success btn-rounded btn-sm'
                              >
                                Approved
                              </button>
                            ) : carrier.status === 'PENDING' ? (
                              <button
                                type='button'
                                className='btn btn-warning btn-rounded btn-sm'
                              >
                                Pending
                              </button>
                            ) : (
                              <button
                                type='button'
                                className='btn btn-danger btn-rounded btn-sm'
                              >
                                Declined
                              </button>
                            )}
                          </td>
                          <td>{formatDateTime(carrier.created_at)}</td>

                          <td>
                            <Link to={`/carrierdetails/${carrier.slug}`}>
                              <button
                                type='button'
                                className='btn btn-inverse-warning btn-fw btn-sm'
                                style={orangeButtonStyle}
                              >
                                Info
                              </button>
                            </Link>
                            {/* <div className="nav-item nav-profile dropdown">
                                      <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">Activate</a>
                                      <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                          <button onClick={() => handleApprove(carrier.slug)} className="dropdown-item mb-2 btn btn-sm bg-danger-light">
                                            Approve
                                          </button>   
                                          <button onClick={() => handleDecline(carrier.slug)} className="dropdown-item btn btn-sm bg-danger-light">
                                              Decline
                                          </button>
                                      </div>
                                    </div> */}

                            {/* <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                      {carrier.status === "pending" && (
                                        <button 
                                          className="dropdown-item mb-2 btn btn-sm bg-danger-light" 
                                          onClick={() => handleActivate(carrier.slug)}
                                        >
                                          Approve
                                        </button>
                                      )}
                                      {(carrier.status === "pending" || carrier.status === "activated") && (
                                        <button 
                                          className="dropdown-item btn btn-sm bg-danger-light" 
                                          onClick={() => handleDecline(carrier.slug)}
                                        >
                                          Decline
                                        </button>
                                      )}
                                    </div> */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-5 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">To Do Lists</h4>
                                            <div className="list-wrapper pt-2">
                                                <ul className="d-flex flex-column-reverse todo-list todo-list-custom">
                                                    <li>
                                                        <div className="form-check form-check-flat">
                                                            <label className="form-check-label">
                                                                <input className="checkbox" type="checkbox" defaultChecked />
                                                                Meeting with Urban Team
                                                            </label>
                                                        </div>
                                                        <i className="remove ti-close"></i>
                                                    </li>
                                                    <li className="completed">
                                                        <div className="form-check form-check-flat">
                                                            <label className="form-check-label">
                                                                <input className="checkbox" type="checkbox" defaultChecked  />
                                                                Duplicate a project for new customer
                                                            </label>
                                                        </div>
                                                        <i className="remove ti-close"></i>
                                                    </li>
                                                    <li>
                                                        <div className="form-check form-check-flat">
                                                            <label className="form-check-label">
                                                                <input className="checkbox" type="checkbox" defaultChecked />
                                                                Project meeting with CEO
                                                            </label>
                                                        </div>
                                                        <i className="remove ti-close"></i>
                                                    </li>
                                                    <li className="completed">
                                                        <div className="form-check form-check-flat">
                                                            <label className="form-check-label">
                                                                <input className="checkbox" type="checkbox" defaultChecked  />
                                                                Follow up of team zilla
                                                            </label>
                                                        </div>
                                                        <i className="remove ti-close"></i>
                                                    </li>
                                                    <li>
                                                        <div className="form-check form-check-flat">
                                                            <label className="form-check-label">
                                                                <input className="checkbox" type="checkbox" defaultChecked />
                                                                Level up for Antony
                                                            </label>
                                                        </div>
                                                        <i className="remove ti-close"></i>
                                                    </li>
                                                </ul>
                        </div>
                        <div className="add-items d-flex mb-0 mt-2">
                                                <input type="text" className="form-control todo-list-input"  placeholder="Add new task" />
                                                <button className="add btn btn-icon text-primary todo-list-add-btn bg-transparent"><i className="icon-circle-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                    </div> */}
      </div>

      <div class='row'>
        <div class='col-md-7 grid-margin stretch-card'>
          <div class='card'>
            <div class='card-body'>
              <p class='card-title mb-0'>New Users</p>
              <div class='table-responsive'>
                <table class='table table-striped table-borderless'>
                  <thead>
                    <tr>
                      <th class='font-weight-bold'>Name</th>
                      <th class='font-weight-bold'>Email</th>
                      <th class='font-weight-bold'>Phone Number</th>
                      <th class='font-weight-bold'>Role</th>
                      <th class='font-weight-bold'>Date</th>
                      <th class='font-weight-bold'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users &&
                      users.length > 0 &&
                      users
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((user, index) => (
                          <tr key={index}>
                            {user.role === 'carrier' ? (
                              <td>{user.details.company_name}</td>
                            ) : (
                              <td>{user.details.username}</td>
                            )}
                            <td>{user.email}</td>
                            {user.role === 'carrier' ? (
                              <td>{user.details.local_phone_number}</td>
                            ) : (
                              <td>{user.details.phone_number}</td>
                            )}

                            <td>{user.role}</td>
                            <td>{formatDateTime(user.details.created_at)}</td>
                            <td>
                              <Link to={`/customersdetails/${user.slug}`}>
                                <button
                                  type='button'
                                  className='btn btn-inverse-warning btn-fw btn-sm'
                                  style={orangeButtonStyle}
                                >
                                  Info
                                </button>
                              </Link>
                              {/* <button onClick={toggleStatus}>{userStatus === 'active' ? 'Suspend' : 'Activate'}</button> */}
                              {/* <UserStatusButton email={user.email} currentStatus={user.account_status} /> */}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class='col-md-5 grid-margin stretch-card'>
          <div class='card'>
            <div class='card-body'>
              <h4 class='card-title'>New Shipping Offers</h4>
              <div class='list-wrapper pt-2'>
                {/* <ul class="d-flex flex-column-reverse todo-list todo-list-custom">
											<li>
												<div class="form-check form-check-flat">
													<label class="form-check-label">
														<input class="checkbox" type="checkbox" />
														Meeting with Urban Team
													</label>
												</div>
												<i class="remove ti-close"></i>
											</li>
											<li class="completed">
												<div class="form-check form-check-flat">
													<label class="form-check-label">
														<input class="checkbox" type="checkbox" checked />
														Duplicate a project for new customer
													</label>
												</div>
												<i class="remove ti-close"></i>
											</li>
											<li>
												<div class="form-check form-check-flat">
													<label class="form-check-label">
														<input class="checkbox" type="checkbox" />
														Project meeting with CEO
													</label>
												</div>
												<i class="remove ti-close"></i>
											</li>
											<li class="completed">
												<div class="form-check form-check-flat">
													<label class="form-check-label">
														<input class="checkbox" type="checkbox" checked />
														Follow up of team zilla
													</label>
												</div>
												<i class="remove ti-close"></i>
											</li>
											<li>
												<div class="form-check form-check-flat">
													<label class="form-check-label">
														<input class="checkbox" type="checkbox" />
														Level up for Antony
													</label>
												</div>
												<i class="remove ti-close"></i>
											</li>
										</ul> */}
              </div>
              <div class='add-items d-flex mb-0 mt-2'>
                <input
                  type='text'
                  class='form-control todo-list-input'
                  placeholder='Add new task'
                />
                <button class='add btn btn-icon text-primary todo-list-add-btn bg-transparent'>
                  <i class='icon-circle-plus'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
                    <div className="col-md-4 stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body">
                        <p className="card-title mb-0">Projects</p>
                        <div className="table-responsive">
                            <table className="table table-borderless">
                            <thead>
                                <tr>
                                <th className="pl-0  pb-2 border-bottom">Places</th>
                                <th className="border-bottom pb-2">Orders</th>
                                <th className="border-bottom pb-2">Users</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td className="pl-0">Kentucky</td>
                                <td><p className="mb-0"><span className="font-weight-bold mr-2">65</span>(2.15%)</p></td>
                                <td className="text-muted">65</td>
                                </tr>
                                <tr>
                                <td className="pl-0">Ohio</td>
                                <td><p className="mb-0"><span className="font-weight-bold mr-2">54</span>(3.25%)</p></td>
                                <td className="text-muted">51</td>
                                </tr>
                                <tr>
                                <td className="pl-0">Nevada</td>
                                <td><p className="mb-0"><span className="font-weight-bold mr-2">22</span>(2.22%)</p></td>
                                <td className="text-muted">32</td>
                                </tr>
                                <tr>
                                <td className="pl-0">North Carolina</td>
                                <td><p className="mb-0"><span className="font-weight-bold mr-2">46</span>(3.27%)</p></td>
                                <td className="text-muted">15</td>
                                </tr>
                                <tr>
                                <td className="pl-0">Montana</td>
                                <td><p className="mb-0"><span className="font-weight-bold mr-2">17</span>(1.25%)</p></td>
                                <td className="text-muted">25</td>
                                </tr>
                                <tr>
                                <td className="pl-0">Nevada</td>
                                <td><p className="mb-0"><span className="font-weight-bold mr-2">52</span>(3.11%)</p></td>
                                <td className="text-muted">71</td>
                                </tr>
                                <tr>
                                <td className="pl-0 pb-0">Louisiana</td>
                                <td className="pb-0"><p className="mb-0"><span className="font-weight-bold mr-2">25</span>(1.32%)</p></td>
                                <td className="pb-0">14</td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4 stretch-card grid-margin">
                    <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                            <p className="card-title">Charts</p>
                            <div className="charts-data">
                                <div className="mt-3">
                                <p className="mb-0">Data 1</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="progress progress-md flex-grow-1 mr-4">
                                    <div className="progress-bar bg-inf0" role="progressbar" style={{ width: '95%' }}  aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p className="mb-0">5k</p>
                                </div>
                                </div>
                                <div className="mt-3">
                                <p className="mb-0">Data 2</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="progress progress-md flex-grow-1 mr-4">
                                    <div className="progress-bar bg-info" role="progressbar" style={{ width: '35%' }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p className="mb-0">1k</p>
                                </div>
                                </div>
                                <div className="mt-3">
                                <p className="mb-0">Data 3</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="progress progress-md flex-grow-1 mr-4">
                                    <div className="progress-bar bg-info" role="progressbar" style={{ width: "48%" }} aria-valuenow="48" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p className="mb-0">992</p>
                                </div>
                                </div>
                                <div className="mt-3">
                                <p className="mb-0">Data 4</p>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="progress progress-md flex-grow-1 mr-4">
                                    <div className="progress-bar bg-info" role="progressbar" style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <p className="mb-0">687</p>
                                </div>
                                </div>
                            </div>  
                            </div>
                        </div>
                        </div>
                        <div className="col-md-12 stretch-card grid-margin grid-margin-md-0">
                        <div className="card data-icon-card-primary">
                            <div className="card-body">
                            <p className="card-title text-white">Number of Meetings</p>                      
                            <div className="row">
                                <div className="col-8 text-white">
                                <h3>34040</h3>
                                <p className="text-white font-weight-500 mb-0">The total number of sessions within the date range.It is calculated as the sum . </p>
                                </div>
                                <div className="col-4 background-icon">
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4 stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body">
                        <p className="card-title">Notifications</p>
                        <ul className="icon-data-list">
                            <li>
                            <div className="d-flex">
                                <img src="images/faces/face1.jpg" alt="user" />
                                <div>
                                <p className="text-info mb-1">Isabella Becker</p>
                                <p className="mb-0">Sales dashboard have been created</p>
                                <small>9:30 am</small>
                                </div>
                            </div>
                            </li>
                            <li>
                            <div className="d-flex">
                                <img src="images/faces/face2.jpg" alt="user" />
                                <div>
                                <p className="text-info mb-1">Adam Warren</p>
                                <p className="mb-0">You have done a great job #TW111</p>
                                <small>10:30 am</small>
                                </div>
                            </div>
                            </li>
                            <li>
                            <div className="d-flex">
                            <img src="images/faces/face3.jpg" alt="user" />
                            <div>
                            <p className="text-info mb-1">Leonard Thornton</p>
                            <p className="mb-0">Sales dashboard have been created</p>
                            <small>11:30 am</small>
                            </div>
                            </div>
                            </li>
                            <li>
                            <div className="d-flex">
                                <img src="images/faces/face4.jpg" alt="user" />
                                <div>
                                <p className="text-info mb-1">George Morrison</p>
                                <p className="mb-0">Sales dashboard have been created</p>
                                <small>8:50 am</small>
                                </div>
                            </div>
                            </li>
                            <li>
                            <div className="d-flex">
                                <img src="images/faces/face5.jpg" alt="user" />
                                <div>
                                <p className="text-info mb-1">Ryan Cortez</p>
                                <p className="mb-0">Herbs are fun and easy to grow.</p>
                                <small>9:00 am</small>
                                </div>
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                        <p className="card-title">Advanced Table</p>
                        <div className="row">
                            <div className="col-12">
                            <div className="table-responsive">
                                <table id="example" className="display expandable-table" style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                    <th>Quote#</th>
                                    <th>Product</th>
                                    <th>Business type</th>
                                    <th>Policy holder</th>
                                    <th>Premium</th>
                                    <th>Status</th>
                                    <th>Updated at</th>
                                    <th></th>
                                    </tr>
                                </thead>
                            </table>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>

                        
                    </div>
                </div>  */}
      {/* <!-- content-wrapper ends -->
                <!-- partial:partials/_footer.html --> */}
      {/* <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                    <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021.  Premium <a href="https://www.bootstrapdash.com/" target="_blank">Bootstrap admin template</a> from BootstrapDash. All rights reserved.</span>
                    <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="ti-heart text-danger ml-1"></i></span>
                </div>
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                    <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Distributed by <a href="https://www.themewagon.com/" target="_blank">Themewagon</a></span> 
                </div>
                </footer>  */}
      {/* <!-- partial --> */}
    </div>
  );
}
