import React from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../apiConfig';
import {Container, Row, Col} from 'react-bootstrap';


export default function TypeDetails() {
  const [itemDetails, setItemDetails] = useState({});
  const [rowData, setRowData] = useState(null);
  const {slug} = useParams();
  
  
  useEffect(() => {
   
    // Make the API call to fetch the whole row data using the stored field value
    if (slug) {
      console.log(slug);
      axios.get(`${API_URL}/types/${slug}`)
        .then(response => {
          setRowData(response.data.data); // Store the row data in state
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746'
    
  };

  
    return (              
      <div className="">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">TYPE DETAILS</h4><hr></hr>
                {rowData && (
                  <Container >
                    <Row style={{ height: "50px" }}>
                        <Col>Title</Col>
                        <Col>{rowData.name}</Col>
                      </Row>   
                      <Row style={{ height: "50px" }}>
                        <Col>description</Col>
                        <Col>{rowData.description}</Col>
                      </Row>
                      <Row style={{ height: "50px" }}>
                        <Col>Title</Col>
                        <Col>{rowData.created_at}</Col>
                      </Row>   
                      <Row style={{ height: "50px" }}>
                        <Col>description</Col>
                        <Col>{rowData.description}</Col>
                      </Row>
                  </Container>                      
                                                                                     
                )}                  
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
