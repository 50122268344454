import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import axios from 'axios';

// function handleDeleteClick(slug) {
//   useEffect(() => {
//     const storedToken = localStorage.getItem("token");
//     setToken(storedToken);
//   }, []);

//   return axios.delete(`https://offmyyard-api.herokuapp.com/api/v1/service-charges/${slug}`)
//     .then(response => {
//       setMessage(res.data.message);
//       console.log(response.data);
//       return response.data;
//     })
//     .catch(error => {
//       console.error(error);
//     });
// }

export default function ViewServiceCharge() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [serviceCharges, setServiceCharge] = useState([]);
  const [token, setToken] = useState(null);

  // GET with fetch API
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  // useEffect(() => {
  // const fetchServiceCharge = async () => {
  //   try {
  //     const response = await axios.get(
  //       'https://offmyyard-api.herokuapp.com/api/v1/service-charges',
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setServiceCharge(response.data.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }; if (token) {
  //   fetchServiceCharge();
  // }
  // }, [token]);

  useEffect(() => {
    const fetchServiceCharge = async () => {
      try {
        const response = await axios.get(`${API_URL}/service-charges`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setServiceCharge(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (token) {
      fetchServiceCharge();
    }
  }, [token]);

  const deleteServiceCharge = async (slug) => {
    try {
      const response = await axios.delete(
        `${API_URL}/service-charges/${slug}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      // handle success response
    } catch (error) {
      console.error(error);
      // handle error response
    }
  };

  const handleDeleteClick = (slug) => {
    if (
      window.confirm('Are you sure you want to delete this service charge?')
    ) {
      deleteServiceCharge(slug);
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>ALL SERVICE CHARGE</h4>
              {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Charge</th>
                      <th>Percentage</th>
                      <th>Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceCharges
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((serviceCharge, index) => (
                        <tr key={index}>
                          <td>{serviceCharge.charge}</td>
                          <td>
                            {serviceCharge.is_percentage === 1 ? 'Yes' : 'No'}
                          </td>
                          <td>
                            {serviceCharge.is_active === 1 ? 'Yes' : 'No'}
                          </td>
                          <td>
                            <Link
                              to={`/servicechargedetails/${serviceCharge.slug}`}
                            >
                              <button
                                type='button'
                                className='btn btn-inverse-warning btn-fw btn-sm'
                                style={orangeButtonStyle}
                              >
                                Info
                              </button>
                            </Link>

                            {/* <Link to={`/serviceChargedetails/${serviceCharge.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm" style={orangeButtonStyle}>Info</button></Link>                                  */}
                            <Link
                              to={`/editserviceCharge/${serviceCharge.slug}`}
                            >
                              <button className='btn btn-outline-success btn-sm ml-3'>
                                <i className=' ti-pencil-alt'></i>{' '}
                              </button>
                            </Link>
                            <button
                              className='btn btn-outline-danger btn-sm ml-3'
                              onClick={handleDeleteClick}
                            >
                              <i className='remove ti-trash'></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component='div'
                  rowsPerPage={rowsPerPage}
                  count={serviceCharges.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
