import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from '../../apiConfig';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Icon,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
  } from '@mui/material';

      const EditTypes = () => {
        const [formData, setFormData] = useState({ name: '', description: '' });
        const [isAuthorized, setIsAuthorized] = useState(false);
        const [token, setToken] = useState(null);
      
        // const handleChange = (event) => {
        //   setFormData({
        //     ...formData,
        //     [event.target.name]: event.target.value,
        //   });
        // };

        useEffect(() => {
          const storedToken = localStorage.getItem("token");
          setToken(storedToken);
        }, []);


        useEffect(() => {
          axios.get(`${API_URL}/types`)
            .then((response) => {
              // set initial form data to current data fetched from the API
              setFormData(response.data);
            })
            .catch((error) => {
              // handle error
              console.log(error);
            });
        }, []);

        const handleChange = (event) => {
          const { name, value } = event.target;
          setFormData((prevState) => ({ ...prevState, [name]: value }));
        };
        
        // const handleChange = (event) => {
        //     const formData = {
        //       [event.target.name]: event.target.value,              
        //     };
        //     setFormData(prev => ({...prev, ...formData}))
        // };


      const handleSubmit  = async (event) => {
        event.preventDefault();

        if (!token) {
            return;
          }
      
        if (!isAuthorized) {
          return alert("You are not authorized to perform this action");
        }
      
        const res = await axios
          .put(`${API_URL}/types`, formData, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      };

      // const handleSubmit = (event) => {
      //   event.preventDefault();
      //   axios.put('https://offmyyard-api.herokuapp.com/api/v1/types', formData)
      //     .then((response) => {
      //       // handle successful response
      //       console.log(response);
      //     })
      //     .catch((error) => {
      //       // handle error
      //       console.log(error);
      //     });
      // };
    
  
    return (              
      <div className="">
        <div className="row">
          <div className="col-md-10 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">EDIT TYPES</h4>
                <form className="forms-sample" onSubmit={handleSubmit}>
                  <div className="form-group row">
                    <label for="exampleInputUsername2" className="col-sm-3 col-form-label text-muted">Name</label>
                    <div className="col-sm-9">
                      <input 
                          type="text" 
                          className="form-control" 
                          id="exampleInputUsername2" 
                          placeholder="Name" 
                          name="name"
                          onChange={handleChange}
                          value={formData.name}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label for="exampleInputUsername2" className="col-sm-3 col-form-label text-muted">Description</label>
                    <div className="col-sm-9">
                      <textarea 
                          type="text" 
                          className="form-control" 
                          id="exampleInputUsername2" 
                          placeholder="Description"
                          name="description"
                          onChange={handleChange}
                          value={formData.description} 
                      />
                    </div>
                  </div>                    
                  <button type="submit" className="btn btn-primary mr-2">Update</button>
                  <button className="btn btn-light">Cancel</button>
                </form>
              </div>
            </div>
          </div>          
        </div>
      </div>
    )
  };

  export default EditTypes;