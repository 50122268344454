import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import { format } from 'date-fns';

export default function ViewItems() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [items, setItems] = useState([]);  

  // GET with fetch API
  useEffect(() => {
    const fetchItems = async () => {
       const response = await fetch(
        `${API_URL}/items`
       );
       const data = await response.json();
       setItems(data.data);
    };
    fetchItems();
 }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746'
    
  };
  
    return (              
      <div className="">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-muted">ALL ITEMS</h4>
                {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Negotiable</th>
                        <th>Price</th>
                        <th>Physical Inspection</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                          {items
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, index) => (                            
                              <tr key={index}>
                                <td>{item.title}</td>
                                <td>{item.is_negotiable === 1 ? "Yes" : "No"}</td>
                                <td>{item.price}</td>
                                <td>{item.virtual_inspection === 1 ? "Yes" : "No"}</td>
                                <td>{format(new Date(item.created_at), 'yyyy/MM/dd kk:mm:ss')}</td>
                                <td>
                                    <Link to={`/itemdetails/${item.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm" style={orangeButtonStyle}>Info</button></Link>
                                    {/* <button type="button" className="btn btn-outline-info btn-orange btn-sm " style={orangeButtonStyle}>Info</button> */}
                                    <button className="btn btn-outline-danger btn-sm ml-3"><i className="remove ti-trash"></i></button>
                                    
                                  </td>
                                {/* <td>
                                  <button className="badge badge-success"><i className=" ti-pencil-alt"></i> Edit</button>
                                  <button className="badge badge-danger ml-3"><i className="remove ti-trash"></i>Delete</button>
                                </td> */}
                              </tr> 
                              ))}                         
                    </tbody>
                  </table>
                  <TablePagination
                      sx={{ px: 2 }}
                      page={page}
                      component="div"
                      rowsPerPage={rowsPerPage}
                      count={items.length}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[5, 10, 25]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      nextIconButtonProps={{ "aria-label": "Next Page" }}
                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
