import './navbar.css';
import { Link } from 'react-router-dom';
import { API_URL } from '../apiConfig';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);

  // Set a timer for 30 minutes
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleLogout();
    }, 30 * 60 * 1000);
    setTimer(timeout);
    return () => clearTimeout(timeout);
  }, []);

  const handleLogout = async (token, setLogoutSuccess) => {
    try {
      await axios.post(`${API_URL}/logout`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // If the server returns a 401 status code, redirect to the login page
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
          return Promise.reject(error);
        }
      );

      navigate('/');
      setLogoutSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };
  function logout() {
    navigate('/');
  }

  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className='navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row'>
      <nav className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'>
        <a className='brand-name navbar-brand brand-logo ml-4 ' href='/home'>
          <img src='assets/images/Offmyyard_01.png' alt='logo' />
        </a>
        <a className='navbar-brand brand-logo-mini ' href='/home'>
          <img src='assets/images/Offmyyard_Icon_01.png' alt='logo' />
        </a>
      </nav>
      <nav className='navbar-menu-wrapper d-flex align-items-center justify-content-end'>
        {/* <button
    className="navbar-toggler navbar-toggler align-self-center"
    type="button"
    data-toggle="minimize"
  >
    <span className="icon-menu" />
  </button> */}

        <ul className='navbar-nav navbar-nav-right'>
          {/* Notification Dropdown (Commented out for simplicity) */}
          {/* Profile Dropdown */}
          <li className='nav-item nav-profile dropdown'>
            <a
              className='nav-link dropdown-toggle'
              href='#'
              data-toggle='dropdown'
              id='profileDropdown'
            >
              <img
                src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp'
                alt='profile'
              />
            </a>
            <nav
              className='dropdown-menu dropdown-menu-right navbar-dropdown'
              aria-labelledby='profileDropdown'
            >
              <a className='dropdown-item'>
                <i className='ti-settings text-primary' />
                Settings
              </a>
              <button onClick={logout} className='dropdown-item'>
                <i className='ti-power-off text-primary' />
                Logout
              </button>
            </nav>
          </li>
          {/* Nav Settings */}
          <li className='nav-item nav-settings d-none d-lg-flex'>
            <a className='nav-link' href='#'>
              <i className='icon-ellipsis' />
            </a>
          </li>
        </ul>
        {/* <button
    className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
    type="button"
    data-toggle="offcanvas"
  >
    <span className="icon-menu" />
  </button> */}
      </nav>
    </nav>
  );
}
// Navbar.js
// export default function Navbar() {

//     return (
//       <nav className="navigation">
//         <a href="/" className="brand-name">
//           MacroSoft
//         </a>
//         <button className="hamburger">
//           {/* icon from heroicons.com */}
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             className="h-5 w-5"
//             viewBox="0 0 20 20"
//             fill="white"
//           >
//             <path
//               fillRule="evenodd"
//               d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
//               clipRule="evenodd"
//             />
//           </svg>
//         </button>
//         <div
//           className="navigation-menu">
//           <ul>
//             <li>
//               <a href="/home">Home</a>
//             </li>
//             <li>
//               <a href="/about">About</a>
//             </li>
//             <li>
//               <a href="/contact">Contact</a>
//             </li>
//           </ul>
//         </div>
//       </nav>
//     );
//   }
