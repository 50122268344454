import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Alert } from 'react-bootstrap';

const CreateMake = () => {
  const [formData, setFormData] = useState({});
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  const handleChange = (event) => {
    const formData = {
      [event.target.name]: event.target.value,
    };
    setFormData((prev) => ({ ...prev, ...formData }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('token', token);
    console.log('formData', formData);
    if (!token) {
      return alert('You are not authorized to perform this action');
    }
    await axios
      .post(`${API_URL}/makes`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMessage(res.data.message);
        console.log(res.data);
        return alert('added successfully');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-md-10 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>CREATE MAKE</h4>
              {message && <Alert variant='success'>{message}</Alert>}
              <form className='forms-sample' onSubmit={handleSubmit}>
                <div className='form-group row'>
                  <label
                    for='exampleInputUsername2'
                    className='col-sm-3 col-form-label text-muted'
                  >
                    Name
                  </label>
                  <div className='col-sm-9'>
                    <input
                      type='text'
                      className='form-control'
                      id='exampleInputUsername2'
                      placeholder='Name'
                      name='name'
                      onChange={handleChange}
                      value={formData.name}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <label
                    for='exampleInputUsername2'
                    className='col-sm-3 col-form-label text-muted'
                  >
                    Description
                  </label>
                  <div className='col-sm-9'>
                    <textarea
                      type='text'
                      className='form-control'
                      id='exampleInputUsername2'
                      placeholder='Description'
                      name='description'
                      onChange={handleChange}
                      value={formData.description}
                    />
                  </div>
                </div>
                <button type='submit' className='btn btn-primary mr-2'>
                  Submit
                </button>
                <button className='btn btn-light'>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMake;
