import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { format } from 'date-fns';

export default function ViewModel() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [models, setModels] = useState([]);  

  // GET with fetch API
  useEffect(() => {
    const fetchModels = async () => {
       const response = await fetch(
        `${API_URL}/models`
       );
       const data = await response.json();
       setModels(data.data);
    };
    fetchModels();
 }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746'
    
  };

    return (              
      <div className="">   
            <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-muted">ALL MODELS</h4>
              {/* <p className="card-description">
                Add className <code>.table-striped</code>
              </p> */}
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                        {models
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((model, index) => (                            
                            <tr key={index}>
                              <td>{model.name}</td>
                              <td>{model.description}</td>
                              <td>{format(new Date(model.created_at), 'yyyy/MM/dd kk:mm:ss')}</td>
                              <td>
                                  <Link to={`/modeldetails/${model.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm ml-3" style={orangeButtonStyle}>Info</button></Link>                                 
                                  <Link to={`/editmodel/${model.slug}`}><button className="btn btn-outline-success btn-sm ml-3"><i className=" ti-pencil-alt"></i> </button></Link>
                                  <button className="btn btn-outline-danger btn-sm ml-3"><i className="remove ti-trash"></i></button>
                                </td>
                            </tr> 
                            ))}                         
                        </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    )
  }
