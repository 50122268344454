import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Route, Outlet } from 'react-router-dom';
import Sidebar from './sidebar/sidebar';
import axios from 'axios';

function App() {
  const [token, setToken] = useState(null);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // Redirect to login page
        window.location.href = '/';
      }

      return Promise.reject(error);
    }
  );

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Simulate a 401 error response
  //       throw new Error('Unauthorized');
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       if (error.message === 'Unauthorized') {
  //         window.location.href = '/';
  //       }
  //     }
  //   };

  //   const isAuthenticated = JSON.parse(localStorage.getItem('token'));

  //   if (!isAuthenticated) {
  //     window.location.href = '/';
  //   } else {
  //     fetchData();
  //   }
  // }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem('user-token');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      <div className='container-scroller'>
        {isLoggedIn && <Sidebar />}
        <div className='main-panel'>
          <div className='content-wrapper'>
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
