import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TablePagination } from '@mui/material';
import axios from 'axios';

export default function ViewContactMessage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [contactMessages, setContactMessage] = useState([]);
  const [token, setToken] = useState(null);

  // GET with fetch API
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    const fetchContactMessage = async () => {
      try {
        const response = await axios.get(
          'https://offmyyard-api.herokuapp.com/api/v1/contacts',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setContactMessage(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (token) {
      fetchContactMessage();
    }
  }, [token]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>ALL CONTACTS</h4>
              {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>subject</th>
                      <th>message</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactMessages
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((ContactMessage, index) => (
                        <tr key={index}>
                          <td>{ContactMessage.name}</td>
                          <td>{ContactMessage.email}</td>
                          <td>{ContactMessage.subject}</td>
                          <td>{ContactMessage.message}</td>
                          <td>{ContactMessage.status}</td>
                          <td>
                            <Link
                              to={`/ContactMessagedetails/${ContactMessage.slug}`}
                            >
                              <button
                                type='button'
                                className='btn btn-inverse-warning btn-fw btn-sm'
                                style={orangeButtonStyle}
                              >
                                Info
                              </button>
                            </Link>

                            {/* <Link to={`/ContactMessagedetails/${ContactMessage.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm" style={orangeButtonStyle}>Info</button></Link>                                 
                                  <Link to={`/editContactMessage/${ContactMessage.id}`}><button className="btn btn-outline-success btn-sm ml-3"><i className=" ti-pencil-alt"></i> </button></Link> */}
                            <button className='btn btn-outline-danger btn-sm ml-3'>
                              <i className='remove ti-trash'></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component='div'
                  rowsPerPage={rowsPerPage}
                  count={contactMessages.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
