import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../apiConfig';
import { format } from 'date-fns';
import { TablePagination } from '@mui/material';
import RefreshToken from '../../auth/refreshToken';

export default function ViewWallet() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [wallets, setWallets] = useState([]);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await axios.get(`${API_URL}/wallets`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWallets(response.data.data);
        // console.log(setwallet);
      } catch (error) {
        if (error.response.status === 401) {
          // Access token has expired or is invalid
          const newAccessToken = await RefreshToken();
          const response = await axios.get(`${API_URL}/wallets`, {
            headers: { Authorization: `Bearer ${newAccessToken}` },
          });
          setWallets(response.data.data);
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    if (token) {
      fetchWallets();
    }
  }, [token]);

  useEffect(() => {
    console.log(wallets);
  }, [wallets]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };
  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>WALLET</h4>
              {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Bank Name</th>
                      <th>Bank Account</th>
                      <th>Balance</th>
                      <th>Routing Number</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wallets &&
                      wallets.length > 0 &&
                      wallets
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((wallet, index) => (
                          <tr key={index}>
                            <td>{wallet.bank_name}</td>
                            <td>{wallet.bank_account}</td>
                            <td>
                              {wallet.balance.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </td>
                            <td>{wallet.routing_number}</td>
                            <td>
                              {format(
                                new Date(wallet.created_at),
                                'yyyy/MM/dd kk:mm:ss'
                              )}
                            </td>
                            <td>
                              <Link to={`/walletsdetails/${wallet.slug}`}>
                                <button
                                  type='button'
                                  className='btn btn-inverse-warning btn-fw btn-sm'
                                  style={orangeButtonStyle}
                                >
                                  Info
                                </button>
                              </Link>
                              <button className='btn btn-outline-danger btn-sm ml-3'>
                                <i className='remove ti-trash'></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component='div'
                  rowsPerPage={rowsPerPage}
                  count={wallets.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
