import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TablePagination, CircularProgress } from '@mui/material';
import axios from 'axios';

export default function ViewShippingOffer() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shippingOffers, setShippingOffer] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  // GET with fetch API
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchShippingOffer = async () => {
      try {
        const response = await axios.get(`${API_URL}/shipping-offers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) setLoading(false);
        setShippingOffer(response.data.data);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    if (token) {
      fetchShippingOffer();
    }
  }, [token]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>ALL SHIPPING OFFERS</h4>
              {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Ship Rrom</th>
                      <th>Ship To</th>
                      <th>Shipping Offer Amount</th>
                      <th>Pickup Date</th>
                      <th>Pickup address</th>
                      <th>Pickup Location Type</th>
                      <th>Drop Address</th>
                      <th>Drop Location Type</th>
                      <th>Down Payment</th>
                      <th>Shipping Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan='7' style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {shippingOffers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((shippingOffer, index) => (
                            <tr key={index}>
                              <td>{shippingOffer.ship_from}</td>
                              <td>{shippingOffer.ship_to}</td>
                              <td>
                                {shippingOffer.shipping_offer_amount.toLocaleString(
                                  'en-US',
                                  { style: 'currency', currency: 'USD' }
                                )}
                              </td>
                              <td>{shippingOffer.pickup_address}</td>
                              <td>{shippingOffer.pickup_date}</td>
                              <td>{shippingOffer.pickup_location_type}</td>
                              <td>{shippingOffer.drop_address}</td>
                              <td>{shippingOffer.drop_location_type}</td>
                              <td>{shippingOffer.down_payment}</td>
                              <td>{shippingOffer.shipping_status}</td>
                              <td>
                                <Link
                                  to={`/shippingOfferdetails/${shippingOffer.slug}`}
                                >
                                  <button
                                    type='button'
                                    className='btn btn-inverse-warning btn-fw btn-sm'
                                    style={orangeButtonStyle}
                                  >
                                    Info
                                  </button>
                                </Link>

                                {/* <Link to={`/shippingOfferdetails/${shippingOffer.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm" style={orangeButtonStyle}>Info</button></Link>                                 
                                  <Link to={`/editshippingOffer/${shippingOffer.id}`}><button className="btn btn-outline-success btn-sm ml-3"><i className=" ti-pencil-alt"></i> </button></Link> */}
                                <button className='btn btn-outline-danger btn-sm ml-3'>
                                  <i className='remove ti-trash'></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </>
                    )}
                  </tbody>
                </table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component='div'
                  rowsPerPage={rowsPerPage}
                  count={shippingOffers.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
