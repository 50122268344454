import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { TablePagination, CircularProgress } from '@mui/material';

function UserStatusButton({ email, currentStatus }) {
  console.log(currentStatus);
  const [userStatus, setUserStatus] = useState(currentStatus);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  const toggleStatus = () => {
    const newStatus = userStatus === 'ACTIVE' ? 'suspended' : 'active';

    axios
      .post(
        `${API_URL}/suspend-and-activate-account`,
        { email: email, status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setUserStatus((prevStatus) =>
          prevStatus === 'ACTIVE' ? 'suspended' : 'ACTIVE'
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <button class='btn btn-outline-success btn-sm ml-3' onClick={toggleStatus}>
      {userStatus === 'ACTIVE' ? 'Active' : 'Suspended'}
    </button>
  );
}

export default function ViewUsers() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${API_URL}/users`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) setLoading(false);
        setUsers(response.data.data);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };
    if (token) {
      fetchUsers();
    }
  }, [token]);

  useEffect(() => {
    console.log(users);
  }, [users]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>All Users</h4>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      {/* <th>Name</th> */}
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Address</th>
                      <th>Role</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan='7' style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {users &&
                          users.length > 0 &&
                          users
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((user, index) => (
                              <tr key={index}>
                                {user.role === 'carrier' ? (
                                  <td>{user.details.company_name}</td>
                                ) : (
                                  <td>{user.details.username}</td>
                                )}
                                <td>{user.email}</td>
                                <td>{user.phone_number}</td>
                                <td>{user.role}</td>
                                <td>
                                  {format(
                                    new Date(user.details.created_at),
                                    'yyyy/MM/dd kk:mm:ss'
                                  )}
                                </td>
                                <td>
                                  <Link to={`/customersdetails/${user.slug}`}>
                                    <button
                                      type='button'
                                      className='btn btn-inverse-warning btn-fw btn-sm'
                                      style={orangeButtonStyle}
                                    >
                                      Info
                                    </button>
                                  </Link>
                                  <button className='btn btn-outline-danger btn-sm ml-3'>
                                    <i className='remove ti-trash'></i>
                                  </button>
                                  {/* <button onClick={toggleStatus}>{userStatus === 'active' ? 'Suspend' : 'Activate'}</button> */}
                                  <UserStatusButton
                                    email={user.email}
                                    currentStatus={user.account_status}
                                  />
                                </td>
                              </tr>
                            ))}
                      </>
                    )}
                  </tbody>
                </table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component='div'
                  rowsPerPage={rowsPerPage}
                  count={users.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
