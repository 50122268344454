import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { API_URL } from '../../apiConfig';
import { TablePagination, CircularProgress } from '@mui/material';
import RefreshToken from '../../auth/refreshToken';

export default function ViewTransactions() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [transactions, setTransactions] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`${API_URL}/transactions`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) setLoading(false);
        setTransactions(response.data.data);
        // console.log(settransaction);
      } catch (error) {
        setLoading(false);
        if (error.response.status === 401) {
          // Access token has expired or is invalid
          const newAccessToken = await RefreshToken();
          const response = await axios.get(`${API_URL}/transactions`, {
            headers: { Authorization: `Bearer ${newAccessToken}` },
          });
          setTransactions(response.data.data);
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    if (token) {
      fetchTransactions();
    }
  }, [token]);

  useEffect(() => {
    console.log(transactions);
  }, [transactions]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };
  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title'>All Transactions</h4>
              {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Transaction Status </th>
                      <th>Type</th>
                      <th>Stripe Charge ID</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan='7' style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {transactions &&
                          transactions.length > 0 &&
                          transactions
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((transaction, index) => (
                              <tr key={index}>
                                <td>
                                  {transaction.amount.toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                  })}
                                </td>
                                <td>{transaction.transaction_status}</td>
                                <td>{transaction.type}</td>
                                <td>{transaction.stripe_charge_id}</td>
                                <td>
                                  {format(
                                    new Date(transaction.created_at),
                                    'yyyy/MM/dd kk:mm:ss'
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/transactionsdetails/${transaction.slug}`}
                                  >
                                    <button
                                      type='button'
                                      className='btn btn-inverse-warning btn-fw btn-sm'
                                      style={orangeButtonStyle}
                                    >
                                      Info
                                    </button>
                                  </Link>
                                  <button className='btn btn-outline-danger btn-sm ml-3'>
                                    <i className='remove ti-trash'></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                      </>
                    )}
                  </tbody>
                </table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component='div'
                  rowsPerPage={rowsPerPage}
                  count={transactions.length}
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                  backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
