import React from 'react';
import { API_URL } from '../../apiConfig';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Alert } from 'react-bootstrap';

const EditModel = () => {
  // const [formData, setFormData] = useState({});
  const [token, setToken] = useState(null);
  const { slug } = useParams();
  const [options, setOptions] = useState({});
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/models/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFormData({
          name: response.data.data.name,
          description: response.data.data.description,
          make: '', // set the make property to the ID of the make
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/makes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOptions(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [token]);

  // const handleChange = (event) => {
  //   const formData = {
  //     [event.target.name]: event.target.value,
  //   };
  //   setFormData(prev => ({...prev, ...formData}))
  // };
  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = { ...formData, [name]: value };
    setFormData(updatedFormData);
    console.log(updatedFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log('formData',formData)

    const res = await axios
      .patch(`${API_URL}/models/${slug}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMessage(res.data.message);
        setFormData({});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-md-10 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>EDIT MODEL</h4>
              {message && <Alert variant='success'>{message}</Alert>}
              <form className='forms-sample' onSubmit={handleSubmit}>
                <div className='form-group row'>
                  <label
                    for='exampleInputUsername2'
                    className='col-sm-3 col-form-label text-muted'
                  >
                    Name
                  </label>
                  <div className='col-sm-9'>
                    <input
                      type='text'
                      className='form-control'
                      id='exampleInputUsername2'
                      // placeholder="Namee"
                      name='name'
                      onChange={handleChange}
                      value={formData.name}
                    />
                  </div>
                </div>
                <div className='form-group row'>
                  <label
                    htmlFor='exampleInputUsername2'
                    className='col-sm-3 col-form-label text-muted'
                  >
                    Make
                  </label>
                  <div className='col-sm-9'>
                    <select
                      className='form-control'
                      name='make'
                      value={formData.make}
                      onChange={handleChange}
                    >
                      {options.length > 0 &&
                        options.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            id='exampleInputUsername2'
                          >
                            {option.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className='form-group row'>
                  <label
                    for='exampleInputUsername2'
                    className='col-sm-3 col-form-label text-muted'
                  >
                    Description
                  </label>
                  <div className='col-sm-9'>
                    <textarea
                      type='text'
                      className='form-control'
                      id='exampleInputUsername2'
                      // placeholder="Description"
                      name='description'
                      onChange={handleChange}
                      value={formData.description}
                      //  value={formData.description || ''}
                    />
                  </div>
                </div>
                <button type='submit' className='btn btn-primary mr-2'>
                  Submit
                </button>
                <button className='btn btn-light'>Cancel</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModel;
