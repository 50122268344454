import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import axios from 'axios';
import {Container, Row, Col} from 'react-bootstrap';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBtn,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBProgress,
  MDBProgressBar,
  MDBIcon,
  MDBListGroup,
  MDBListGroupItem
} from 'mdb-react-ui-kit';


export default function MakeDetails() {
  const [items, setItems] = useState([]);  
  const [itemDetails, setItemDetails] = useState({});
  const [rowData, setRowData] = useState(null);
  const {slug} = useParams();
  
  
  useEffect(() => {
   
    // Make the API call to fetch the whole row data using the stored field value
    if (slug) {
      console.log(slug);
      axios.get(`${API_URL}/makes/${slug}`)
        .then(response => {
          setRowData(response.data.data); // Store the row data in state
          console.log(response.data.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746'
    
  };

  
    return (
      <section style={{ backgroundColor: '#eee' }}>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol>
            <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
              <MDBBreadcrumbItem>
                <Link to='/home'>Home</Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem>
                <Link to="/viewmake">Make</Link>
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>Make Details</MDBBreadcrumbItem>
            </MDBBreadcrumb>
          </MDBCol>
        </MDBRow>
        {rowData && (          
        <MDBRow>
          
          <MDBCol lg="10">
            <MDBCard className="mb-4">
              <MDBCardBody>
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Name</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{rowData.name}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
                <MDBRow>
                  <MDBCol sm="3">
                    <MDBCardText>Description</MDBCardText>
                  </MDBCol>
                  <MDBCol sm="9">
                    <MDBCardText className="text-muted">{rowData.description}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <hr />
              </MDBCardBody>
            </MDBCard>

            <MDBRow>
            </MDBRow>
          </MDBCol>
        </MDBRow>
         )}

        <div id="section-1">
          <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                  <div className="card">
                      <div className="card-body">
                          <h4 className="card-title text-muted">ALL MODELS ASSOCIATED WITH THIS MAKE</h4>                              
                          <hr></hr>
                              <div className="table-responsive">
                              <table className="table table-striped">
                                  <thead>
                                      <tr>
                                      <th>ID</th>
                                      <th>Name</th>
                                      <th>Description</th>
                                      <th>Date Created</th>                                      
                                      <th>Updated_at </th>
                                      </tr>
                                  </thead>
                                  <tbody>                           
                                    { rowData && rowData.relationships && (
                                        <>
                                            { rowData.relationships.models && Array.isArray(rowData.relationships.models) && rowData.relationships.models.map((model) => (
                                                <tr key={model.id}> 
                                                    <td>{model.id}</td>
                                                    <td>{model.name}</td>
                                                    <td>{model.description}</td>
                                                    <td>{model.created_at}</td>
                                                    <td>{model.updated_at}</td>
                                                </tr>
                                            ))}                                            
                                        </>
                                    )}
                                  </tbody>
                                </table>
                              </div>                        
                            </div>                
                          </div>
                        </div>
          </div>
        </div>

      </MDBContainer>
    </section>
      
      


      // <div className="">
      //   <div className="row">
      //     <div className="col-lg-12 grid-margin stretch-card">
      //       <div className="card">
      //         <div className="card-body">
      //           <h4 className="card-title text-muted">MAKE DETAILS</h4><hr></hr>
      //           {rowData && (
      //             <Container >
      //               <Row style={{ height: "50px" }}>
      //                   <Col>Title</Col>
      //                   <Col>{rowData.name}</Col>
      //                 </Row>   
      //                 <Row style={{ height: "50px" }}>
      //                   <Col>description</Col>
      //                   <Col>{rowData.description}</Col>
      //                 </Row>
      //                 <Row style={{ height: "50px" }}>
      //                   <Col>Title</Col>
      //                   <Col>{rowData.name}</Col>
      //                 </Row>   
      //                 <Row style={{ height: "50px" }}>
      //                   <Col>description</Col>
      //                   <Col>{rowData.description}</Col>
      //                 </Row>
      //             </Container>                      
                                                                                     
      //           )}
                  
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
    )
  }
