import React from 'react';
import { API_URL } from '../../apiConfig';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TablePagination, CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import axios from 'axios';

export default function ViewCarriers() {
  const [carriers, setCarriers] = useState([]);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(null);
  const [status, setStatus] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // GET with fetch API
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  const fetchCarriers = async (page = 1) => {
    try {
      const response = await axios.get(`${API_URL}/carriers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
        },
      });
      setLoading(false);
      setCarriers(response.data.data);
      setPaginationData(response.data.meta);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchCarriers();
    }
  }, [token]);

  const handlePageChange = (event, newPage) => {
    setLoading(true);
    fetchCarriers(newPage + 1);
  };

  // useEffect(() => {
  //   setLoading(true);
  //     const fetchCarriers = async () => {
  //       try {
  //         const response = await axios.get(
  //           `${API_URL}/carriers`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );
  //         if (response.data) setLoading(false);
  //         setCarriers(response.data.data);
  //         setPaginationData(response.data.meta);

  //       } catch (error) {
  //         setLoading(false);
  //         console.error(error);
  //       }
  //     }; if (token) {
  //       fetchCarriers();
  //     }
  //
  // }, [token]);

  // const handlePageChange = (url) => {
  //   setLoading(true);
  //   fetchCarriers(url);
  // };

  // const handleActivate = async (carrierSlug) => {
  //   try {
  //     const response = await axios.put(
  //       `https://offmyyard-api.herokuapp.com/api/v1/carriers-status/${carrierSlug}`,
  //       { status: 'approved' },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const handleApprove = async (carrierId) => {
    try {
      await axios.put(
        `https://offmyyardbackendstaging-api.azurewebsites.net/api/v1/carriers-status/${carrierId}`,
        { status: 'approved' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update the status property of the carrier in the carriers state array
      setCarriers((prevCarriers) => {
        const index = prevCarriers.findIndex((c) => c.id === carrierId);
        const updatedCarriers = [...prevCarriers];
        updatedCarriers[index] = {
          ...updatedCarriers[index],
          status: 'approved',
        };
        return updatedCarriers;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateCarrierStatus = async (id, status) => {
    try {
      const response = await axios.put(
        `https://offmyyardbackendstaging-api.azurewebsites.net/api/v1/carriers/${id}`,
        { status: status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // update the carrier status in the state
      setStatus((prevStatus) => ({
        ...prevStatus,
        [id]: response.data.data.status,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDecline = async (carrierSlug) => {
    try {
      const response = await axios.put(
        `https://offmyyardbackendstaging-api.azurewebsites.net/api/v1/carriers-status/${carrierSlug}`,
        { status: 'declined' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const formatDateTime = (dateTime) => {
    const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

    const formattedDate = new Date(dateTime).toLocaleDateString(
      'en-US',
      dateOptions
    );
    const formattedTime = new Date(dateTime).toLocaleTimeString(
      'en-US',
      timeOptions
    );

    return `${formattedDate} | ${formattedTime}`;
  };

  // useEffect(() => {
  //   const fetchCarriers = async () => {
  //     try {
  //       const response = await axios.get(
  //         'https://offmyyard-api.herokuapp.com/api/v1/carriers',
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       // Get the carrier status data from the API endpoint
  //       const carrierStatusResponse = await axios.put(
  //         `https://offmyyard-api.herokuapp.com/api/v1/carriers-status/${slug}`
  //       );

  //       // Map over the carriers array and update the status of each carrier
  //       const updatedCarriers = response.data.data.map((carrier) => {
  //         const carrierStatus = carrierStatusResponse.data[carrier.slug];
  //         if (carrierStatus && carrierStatus.status === 'activated') {
  //           return { ...carrier, status: 'activated' };
  //         }
  //         return carrier;
  //       });

  //       setCarriers(updatedCarriers);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   if (token) {
  //     fetchCarriers();
  //   }
  // }, [token]);

  // useEffect(() => {
  //   const fetchCarriers = async () => {
  //     try {
  //       const response = await axios.get(
  //         'https://offmyyard-api.herokuapp.com/api/v1/carriers',
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );

  //       const updatedCarriers = response.data.data.map(async (carrier) => {
  //         // check if the status is 'pending' or 'declined'
  //         if (carrier.status === 'pending' || carrier.status === 'declined') {
  //           // make a PUT request to update the status to 'activated'
  //           await axios.put(
  //             `https://offmyyard-api.herokuapp.com/api/v1/carriers-status/${carrier.slug}`,
  //             {
  //               status: 'approved',
  //             },
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${token}`,
  //               },
  //             }
  //           );

  //           // update the status in the carrier object and return it
  //           return { ...carrier, status: 'approved' };
  //         }

  //         // status not updated
  //         return carrier;
  //       });

  //       // wait for all the PUT requests to complete and set the state of carriers
  //       Promise.all(updatedCarriers).then((carriers) => {
  //         setCarriers(carriers);
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   if (token) {
  //     fetchCarriers();
  //   }
  // }, [token]);

  // const handleChangePage = (_, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>ALL CARRIERS</h4>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Email</th>
                      <th>Company Address</th>
                      {/* <th>City</th> */}
                      <th>Phone Number</th>
                      <th>Status</th>
                      <th>Action</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan='7' style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </td>
                      </tr>
                    ) : carriers.length === 0 ? (
                      <tr>
                        <td colSpan='7' style={{ textAlign: 'center' }}>
                          No data available.
                        </td>
                      </tr>
                    ) : (
                      <>
                        {carriers.map((carrier, index) => (
                          <tr key={index}>
                            <Link to={`/carrierdetails/${carrier.slug}`}>
                              <td>{carrier.company_name}</td>
                            </Link>
                            <td>{carrier.email}</td>
                            <td>{carrier.company_address}</td>
                            {/* <td>{carrier.city}</td> */}
                            <td>{carrier.local_phone_number}</td>
                            <td>
                              {carrier.status === 'APPROVED' ? (
                                <button
                                  type='button'
                                  className='btn btn-success btn-rounded btn-sm'
                                >
                                  Approved
                                </button>
                              ) : carrier.status === 'PENDING' ? (
                                <button
                                  type='button'
                                  className='btn btn-warning btn-rounded btn-sm'
                                >
                                  Pending
                                </button>
                              ) : (
                                <button
                                  type='button'
                                  className='btn btn-danger btn-rounded btn-sm'
                                >
                                  Declined
                                </button>
                              )}
                            </td>
                            <td>
                              <Link to={`/carrierdetails/${carrier.slug}`}>
                                <button
                                  type='button'
                                  className='btn btn-inverse-warning btn-fw btn-sm'
                                  style={orangeButtonStyle}
                                >
                                  Info
                                </button>
                              </Link>
                            </td>
                            <td>{formatDateTime(carrier.created_at)}</td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
                {paginationData && (
                  <TablePagination
                    sx={{ px: 2 }}
                    page={paginationData ? paginationData.current_page - 1 : 0}
                    component='div'
                    rowsPerPage={paginationData ? paginationData.per_page : 10}
                    count={paginationData ? paginationData.total : 0}
                    onPageChange={handlePageChange}
                    rowsPerPageOptions={[10, 25, 50]}
                    nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                    backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
