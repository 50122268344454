import React from 'react';
import { API_URL } from '../../apiConfig';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from 'mdb-react-ui-kit';
import { Container, Row, Col } from 'react-bootstrap';

export default function ServiceChargeDetails() {
  const [rowData, setRowData] = useState(null);
  const { slug } = useParams();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  useEffect(() => {
    if (token) {
      fetchServiceCharge();
    }
  }, [token]);

  const fetchServiceCharge = () => {
    // Make the API call to fetch the whole row data using the stored field value
    if (slug) {
      axios
        .get(`${API_URL}/service-charges/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setRowData(response.data.data); // Store the row data in state
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746',
  };

  return (
    <div className=''>
      <div className='row'>
        <div className='col-lg-12 grid-margin stretch-card'>
          <div className='card'>
            <div className='card-body'>
              <h4 className='card-title text-muted'>ALL SERVICE DETAILS</h4>
              {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
              {rowData && (
                <Container>
                  <Row style={{ height: '50px' }}>
                    <Col>ID</Col>
                    <Col>{rowData.id}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col>charge</Col>
                    <Col>{rowData.charge}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col>is_percentage</Col>
                    <Col>{rowData.is_percentage === 1 ? 'Yes' : 'No'}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col>is_active</Col>
                    <Col>{rowData.is_active === 1 ? 'Yes' : 'No'}</Col>
                  </Row>
                  <Row style={{ height: '50px' }}>
                    <Col>created_at</Col>
                    <Col>{rowData.created_at}</Col>
                  </Row>
                </Container>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
