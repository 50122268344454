import { API_URL } from '../apiConfig';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Nav, NavDropdown } from 'react-bootstrap';
import Navbar from './navbar';
import './navbar.css';
import 'antd/dist/antd.css';
import {
  DesktopOutlined,
  FileOutlined,
  HomeOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  CreditCardOutlined,
  UserDeleteOutlined,
  ShopOutlined,
  WalletOutlined,
  BranchesOutlined,
  CarOutlined,
  ContainerOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const Sidebar = () => {
  const navigate = useNavigate();
  const [logoutSuccess, setLogoutSuccess] = useState(false);
  const [token, setToken] = useState(null);

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
  }, []);

  const [timer, setTimer] = useState(null);

  // Set a timer for 30 minutes
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleLogout();
    }, 30 * 60 * 1000);
    setTimer(timeout);
    return () => clearTimeout(timeout);
  }, []);

  const handleLogout = async (token, setLogoutSuccess) => {
    try {
      await axios.post(`${API_URL}/logout`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // If the server returns a 401 status code, redirect to the login page
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            navigate('/login');
          }
          return Promise.reject(error);
        }
      );

      navigate('/');
      setLogoutSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  function logout() {
    // Clear the token from local storage
    localStorage.removeItem('token');
    // Redirect the user to the login page
    navigate('/');
  }

  return (
    <Layout>
      <Navbar />
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        className='custom-sider'
      >
        <div className='demo-logo-vertical' />
        <Menu
          theme='dark'
          defaultSelectedKeys={['1']}
          mode='inline'
          className=''
        >
          <Menu.Item key='1' icon={<HomeOutlined />} className='menu-item'>
            <Link to='/home'>Dashboard</Link>
          </Menu.Item>
          <Menu.Item
            key='2'
            icon={<ShoppingCartOutlined />}
            className='menu-item'
          >
            <Link to='/viewshippingoffer'>Shipping Offer</Link>
          </Menu.Item>
          <Menu.Item key='3' icon={<CarOutlined />} className='menu-item'>
            <Link to='/viewcarriers'>Carrier</Link>
          </Menu.Item>
          <Menu.SubMenu
            key='sub1'
            icon={<TeamOutlined />}
            title='Customers'
            className='menu-item'
          >
            <Menu.Item key='4' className='menu-item-sub'>
              <Link to='/viewuscustomers'>US Customers</Link>
            </Menu.Item>
            <Menu.Item key='5' className='menu-item-sub'>
              <Link to='/viewcustomers'>NIG Customers</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key='sub2'
            icon={<ShopOutlined />}
            title='Make'
            className='menu-item'
          >
            <Menu.Item key='6' className='menu-item-sub'>
              <Link to='/createmake'>Create Make</Link>
            </Menu.Item>
            <Menu.Item key='7' className='menu-item-sub'>
              <Link to='/viewmake'>View Make</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key='sub3'
            icon={<ContainerOutlined />}
            title='Model'
            className='menu-item'
          >
            <Menu.Item key='8' className='menu-item-sub'>
              <Link to='/createmodel'>Create Model</Link>
            </Menu.Item>
            <Menu.Item key='9' className='menu-item-sub'>
              <Link to='/viewmodel'>View Model</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key='sub4'
            icon={<BranchesOutlined />}
            title='Types'
            className='menu-item'
          >
            <Menu.Item key='10' className='menu-item-sub'>
              <Link to='/createtypes'>Create Types</Link>
            </Menu.Item>
            <Menu.Item key='11' className='menu-item-sub'>
              <Link to='/viewtypes'>View Types</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item
            key='12'
            icon={<CreditCardOutlined />}
            className='menu-item'
          >
            <Link to='/viewtransactions'>Transactions </Link>
          </Menu.Item>
          <Menu.Item key='13' icon={<DesktopOutlined />} className='menu-item'>
            <Link to='/viewwaitlist'>Waitlist</Link>
          </Menu.Item>
          <Menu.Item key='14' icon={<WalletOutlined />} className='menu-item'>
            <Link to='/viewwallet'>Wallet</Link>
          </Menu.Item>
          {/* <Menu.Item key="15" icon={<DesktopOutlined />} className='menu-item'>
                                <Link to="/viewshippingoffer">Withdrawal Requests</Link>
                            </Menu.Item> */}
          <Menu.Item
            onClick={handleLogout}
            key='2'
            icon={<UserDeleteOutlined />}
            className='menu-item'
          >
            <Link to='/viewshippingoffer'>Logout</Link>
          </Menu.Item>
        </Menu>
      </Layout.Sider>
      <Layout></Layout>
    </Layout>
  );
};
export default Sidebar;
