import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_URL } from '../../apiConfig';
import { TablePagination } from '@mui/material';


import { format } from 'date-fns';

export default function ViewTypes() {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [types, setTypes] = useState([]);  

  // GET with fetch API
  useEffect(() => {
    const fetchTypes = async () => {
       const response = await fetch(
        `${API_URL}/types`
       );
       const data = await response.json();
       setTypes(data.data);
    };
    fetchTypes();
 }, [id]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }
  const orangeButtonStyle = {
    color: '#F99746',
    borderColor: '#F99746'
    
  };

  
    return (              
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">All Types</h4>
                {/* <p className="card-description">
                  Add className <code>.table-striped</code>
                </p> */}
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                          {types
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((type, index) => (                            
                              <tr key={index}>
                                <td>{type.name}</td>
                                <td>{type.description}</td>
                                <td>{format(new Date(type.created_at), 'yyyy/MM/dd kk:mm:ss')}</td>
                                <td>
                                  <Link to={`/typedetails/${type.slug}`}><button type="button" className="btn btn-inverse-warning btn-fw btn-sm ml-3" style={orangeButtonStyle}>Info</button></Link>                                 
                                  <Link to={`/edittypes/${type.slug}`}><button className="btn btn-outline-success btn-sm ml-3"><i className=" ti-pencil-alt"></i> </button></Link>
                                  <button className="btn btn-outline-danger btn-sm ml-3"><i className="remove ti-trash"></i></button>
                                </td>
                              </tr> 
                              ))}                         
                          </tbody>
                  </table>
                  <TablePagination
                    sx={{ px: 2 }}
                    page={page}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={types.length}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[5, 10, 25]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    nextIconButtonProps={{ "aria-label": "Next Page" }}
                    backIconButtonProps={{ "aria-label": "Previous Page" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
